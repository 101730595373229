import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

export const Header = styled.div`
  position: absolute;
  width: 100%;
  height: 72px;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 100;
`;

export const HeaderViewport = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;

  position: relative;
  overflow: visible;
`;

export const SignUpButton = styled.div`
  width: 102px;
  height: 45px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 90px;
  border: none;
  background: var(--s-1, #ffe3e0);
  padding: 0;
`;

export const SignInButton = styled.button`
  width: 86px;
  height: 45px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 90px;
  border: none;
  background: var(--p-5, #f25c54);
  padding: 0;
`;

export const HeaderSideMenu = styled.div`
  width: 269px;
  padding: 24px 16px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-radius: 24px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 12px 20px 0px rgba(90, 90, 90, 0.1);

  backdrop-filter: blur(10px);

  position: absolute;
  top: 54px;
  right: 20px;
`;

export const HeaderSideMenuProfile = styled.div`
  padding: 12px 8px;

  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;

  border-radius: 16px;
  background: var(--G1, #f8f8f8);
`;
