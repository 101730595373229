import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import React, { ReactElement, useState } from 'react';
import { auth } from 'services/firebase';
import * as Styled from './sendResetPassword.style';

import { Colors } from 'common';
import { toast } from 'react-toastify';
import validator from 'validator';
import { sendPasswordResetEmail } from 'api/firebase-api';
import { useNavigate } from 'react-router-dom';
import { Icons } from 'common/images';

function SendResetPassword(): ReactElement {
  const [email, setEmail] = useState('');

  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();

  async function sendEmail() {
    const emailValue = (email ?? '').trim();

    if (!emailValue) {
      alert('이메일을 입력해주세요!');
      return;
    }

    setDisabled(true);

    try {
      await sendPasswordResetEmail(email);
      alert('이메일이 성공적으로 전송되었습니다!');
      navigate('/signin');
    } catch (error: any) {
      if (error.code === 'auth/user-not-found') {
        if (confirm('가입되지 않은 이메일입니다! 멤버 가입 페이지로 이동하시겠어요?')) {
          navigate('/signup');
        }
        return;
      }
      alert(JSON.stringify(error));
    } finally {
      setDisabled(false);
    }
  }

  return (
    <Styled.SendResetPasswordContainer>
      <Styled.BackButton onClick={() => navigate(-1)}>
        <Common.SizedImage src={Icons.Back} width={20} height={21} />
      </Styled.BackButton>

      <Typo.Yangjin fontSize={54} color="#F25C54">
        Forgot password?
      </Typo.Yangjin>

      <Common.SizedBoxH height={70} />

      <Typo.IBM_Regular fontSize={18} color="#393939">
        노마드인서울 계정의 이메일 주소를 알려주세요.
        <br />
        비밀번호 재설정 링크를 보내드릴게요!
      </Typo.IBM_Regular>

      <Common.SizedBoxH height={48} />

      <Styled.SendResetPasswordInput placeholder="이메일" value={email} onChange={(e) => setEmail(e.target.value)} type="email" />

      <Common.SizedBoxH height={24} />

      <Styled.SendResetPasswordButton disabled={disabled || !validator.isEmail(email)} onClick={sendEmail}>
        링크 전송
      </Styled.SendResetPasswordButton>

      <Common.SizedBoxH height={24} />

      <Typo.IBM_SemiBold fontSize={14} color="#F25C54">
        이메일 주소를 잊어버리셨나요?
        <br />
        오른쪽 아래에 있는 깨비에게 도움을 요청해 주세요!
      </Typo.IBM_SemiBold>
    </Styled.SendResetPasswordContainer>
  );
}

export default SendResetPassword;
