import React, { ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FirebaseContext } from 'context/firebaseContext';
import { signOut } from 'firebase/auth';
import { Link, useNavigate, useParams } from 'react-router-dom';

import * as Styled from './namecard.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { CommonImages, Icons, ProviderIcons } from 'common/images';
import { Colors } from 'common';

import * as Storage from 'firebase/storage';
import * as Database from 'firebase/database';
import { auth, db, storage } from 'services/firebase';

import NormalMemberBackgroundImage from 'img/background/Background_1.png';
import PremiumMemberBackgroundImage from 'img/background/Background_2.png';

import DefaultPhoto from 'img/common/profile_default.png';
import { DiscordOauth2Url, MembershipBenefitsUrl } from 'common/urls';
import { copyText, getDiscordProfileUrl } from 'common/utils';

import EmailIcon from 'img/namecardIcons/email.png';
import LinkedInIcon from 'img/namecardIcons/linked_in.png';
import InstagramIcon from 'img/namecardIcons/instagram.png';
import HomepageIcon from 'img/namecardIcons/homepage.png';

import validator from 'validator';
import { disconnectDiscordAccount } from 'api/firebase-api';
import OutsideClickHandler from 'react-outside-click-handler';

const tagDatas = [
  'AI',
  'IT',
  'PM',
  'N잡',
  'UI/UX',
  'Web3',
  '개발',
  '게임',
  '건강/피트니스',
  '건축',
  '경영',
  '경제/사회',
  '과학/기술',
  '공부',
  '교육',
  '글',
  '금융',
  '기획',
  '노코드',
  '대학원',
  '데이터',
  '디자인',
  '마케팅',
  '무역',
  '미디어',
  '백엔드',
  '방송/연예',
  '법률',
  '부동산',
  '뷰티',
  '브랜딩',
  '블록체인',
  '사업',
  '사진',
  '세일즈',
  '스타트업',
  '심리',
  '암호화폐',
  '여행',
  '연구',
  '영상',
  '예술',
  '외국어',
  '온라인클래스',
  '요리',
  '운동',
  '원격근무',
  '의료',
  '음악',
  '인문',
  '인사',
  '일러스트',
  '자기계발',
  '재테크',
  '창업',
  '창작',
  '책',
  '커뮤니티',
  '컨설팅',
  '코칭',
  '콘텐츠',
  '투자',
  '통번역',
  '패션',
  '프론트엔드',
  '프리랜싱',
  '해외취업',
  '환경',
  '회계',
];

function Namecard(): ReactElement {
  const { id } = useParams();

  const { user, userData } = useContext(FirebaseContext);
  const navigate = useNavigate();

  const [edit, setEdit] = useState(false);
  const [data, setData] = useState<UserDto>();
  const [editTags, setEditTags] = useState(false);

  const [editTab, setEditTab] = useState<'basic' | 'introduce' | 'link'>('basic');

  const [username, setUsername] = useState<string>();
  const [membership, setMembership] = useState<MembershipType>();
  const [job, setJob] = useState<string>();
  const [base, setBase] = useState<string>();
  const [tags, setTags] = useState<string[]>();
  const [introduce, setIntroduce] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [linkedIn, setLinkedIn] = useState<string>();
  const [instagram, setInstagram] = useState<string>();
  const [webSite, setWebSite] = useState<string>();

  const [tagsTemp, setTagsTemp] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      if (!id) return;

      try {
        const dbRef = Database.ref(db, `users/${id}`);
        const snapshot = await Database.get(dbRef);
        setData(snapshot.val());
      } catch (error) {
        alert(error);
      }
    })();
  }, []);

  function initEditUserData() {
    setUsername(userData?.username);
    setMembership(userData?.membership);
    setJob(userData?.job);
    setBase(userData?.base);
    setTags([...(userData?.tags ?? [])]);
    setIntroduce(userData?.introduce);
    setEmail(userData?.email);
    setLinkedIn(userData?.linked_in);
    setInstagram(userData?.instagram);
    setWebSite(userData?.web_site);
    setTagsTemp([...(userData?.tags ?? [])]);
  }

  useEffect(() => {
    if (!id) return;

    if (user && userData && user.uid === id) {
      initEditUserData();
    }
  }, [user, userData]);

  const activateEditButton = useMemo(() => {
    if (!user) return false;
    if (!userData) return false;

    if ((userData.username ?? '').trim() !== (username ?? '').trim()) return true;
    if ((userData.job ?? '').trim() !== (job ?? '').trim()) return true;
    if ((userData.base ?? '').trim() !== (base ?? '').trim()) return true;

    if (JSON.stringify(userData.tags ?? []) !== JSON.stringify(tags ?? [])) return true;

    if ((userData.email ?? '').trim() !== (email ?? '').trim()) return true;
    if ((userData.introduce ?? '').trim() !== (introduce ?? '').trim()) return true;
    if ((userData.linked_in ?? '').trim() !== (linkedIn ?? '').trim()) return true;
    if ((userData.instagram ?? '').trim() !== (instagram ?? '').trim()) return true;
    if ((userData.web_site ?? '').trim() !== (webSite ?? '').trim()) return true;

    return false;
  }, [username, job, base, tags, introduce, email, linkedIn, instagram, webSite]);

  const [showProfileImageMenu, setShowProfileImageMenu] = useState(false);
  const inputProfileFile = useRef<HTMLInputElement | null>(null);

  const [showBackgroundImageMenu, setShowBackgroundImageMenu] = useState(false);
  const inputBackgroundFile = useRef<HTMLInputElement | null>(null);

  const handleProfileFileUpload = async (e: any) => {
    if (!user) return;

    const { files } = e.target;
    if (files && files.length) {
      const file: File = files[0];
      console.log(file);

      const storageRef = Storage.ref(storage, `/users/${user.uid}/profile`);
      const uploadTask = Storage.uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {},
        (err) => console.log(err),
        () => {
          // download url
          Storage.getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url);
            const dbRef = Database.ref(db, `users/${user.uid}`);
            Database.update(dbRef, {
              profile: url,
            });
          });
        },
      );
    }
  };

  const handleBackgroundFileUpload = async (e: any) => {
    if (!user) return;

    const { files } = e.target;
    if (files && files.length) {
      const file: File = files[0];
      console.log(file);

      const storageRef = Storage.ref(storage, `/users/${user.uid}/background`);
      const uploadTask = Storage.uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {},
        (err) => console.log(err),
        () => {
          // download url
          Storage.getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url);
            const dbRef = Database.ref(db, `users/${user.uid}`);
            Database.update(dbRef, {
              background: url,
            });
          });
        },
      );
    }
  };

  const onUploadProfileImage = () => {
    inputProfileFile?.current?.click();
  };

  const onUploadBackgroundImage = () => {
    inputBackgroundFile?.current?.click();
  };

  const onRemoveProfileImage = async () => {
    if (!user) return;
    await Database.remove(Database.ref(db, `/users/${user.uid}/profile`));
  };

  const onRemoveBackgroundImage = async () => {
    if (!user) return;
    await Database.remove(Database.ref(db, `users/${user.uid}/background`));
  };

  async function disconnectDiscord() {
    if (!user) return;

    try {
      await disconnectDiscordAccount(user);
    } catch (error) {
      console.log(error);
      alert('에러 발생');
    }
  }

  async function onCancelEdit() {
    // initEditUserData();
    // setEdit(false);
    navigate(0);
  }

  async function onSubmitEdit() {
    if (!user) return;
    if (!id) return;

    if (user.uid !== id) return;

    const emailValue = (email ?? '').trim();

    if (emailValue) {
      if (!validator.isEmail(emailValue)) {
        alert('올바르지 않은 이메일 형식입니다!');
        return;
      }
    }

    const webSiteValue = (webSite ?? '').trim();

    if (webSiteValue) {
      if (!validator.isURL(webSiteValue)) {
        alert('올바르지 않은 홈페이지 주소입니다!');
        return;
      }
    }

    try {
      const dbRef = Database.ref(db, `users/${user.uid}`);
      const newData: any = {};
      if (username) newData.username = username;
      if (job) newData.job = job;
      if (base) newData.base = base;
      if (tags) newData.tags = tags;
      if (introduce) newData.introduce = introduce;
      if (email) newData.email = emailValue;
      if (linkedIn) newData.linked_in = linkedIn;
      if (instagram) newData.instagram = instagram;
      if (webSite) newData.web_site = webSiteValue;
      await Database.update(dbRef, newData);
      setEdit(false);
      navigate(0);
    } catch (error) {
      alert(error);
    }
  }

  const linkedInRef = useRef<any>(null);
  const instagramRef = useRef<any>(null);

  return (
    <Styled.NamecardContainer
      backgroundImage={
        (edit ? userData?.background : data?.background) ??
        (data?.membership === 'premium' ? PremiumMemberBackgroundImage : NormalMemberBackgroundImage)
      }
    >
      {!edit && (
        <>
          <Styled.NamecardContents>
            <Styled.NamecardPhoto src={data?.profile ?? DefaultPhoto} />

            <Common.FlexRow alignItems="center" justifyContent="center">
              <Typo.Yangjin fontSize={18} color="#1B1B1B">
                {data?.username}
              </Typo.Yangjin>
              <Common.SizedBoxW width={10} />
              <Styled.NamecardMembershipTag backgroundColor={data?.membership === 'premium' ? '#F25C54' : '#FBC3BC'}>
                {data?.membership === 'premium' ? (
                  <Typo.IBM_SemiBold fontSize={12} color={Colors.neutralWhite}>
                    GABBI
                  </Typo.IBM_SemiBold>
                ) : (
                  <Typo.IBM_SemiBold fontSize={12} color={Colors.neutralWhite}>
                    WANDERER
                  </Typo.IBM_SemiBold>
                )}
              </Styled.NamecardMembershipTag>
            </Common.FlexRow>

            <Common.SizedBoxH height={24} />

            <Typo.IBM_SemiBold fontSize={18} color="#393939" textAlign="center">
              {data?.job ?? <>노마드인서울 멤버</>}
            </Typo.IBM_SemiBold>

            {data?.base && (
              <>
                <Common.SizedBoxH height={12} />
                <Common.FlexRow alignItems="center" justifyContent="center">
                  <Common.SizedImage src={Icons.Pin} width={13.867} height={16} />
                  <Common.SizedBoxW width={4} />
                  <Typo.IBM_SemiBold fontSize={16} color="#393939">
                    {data.base}
                  </Typo.IBM_SemiBold>
                </Common.FlexRow>
              </>
            )}

            {data?.tags && data.tags.length > 0 && (
              <>
                <Common.SizedBoxH height={12} />
                <Common.FlexRow alignItems="center" justifyContent="center">
                  {data.tags.map((e) => (
                    <Typo.IBM_SemiBold fontSize={16} color="#F25C54" key={e}>
                      &nbsp;#{e}&nbsp;
                    </Typo.IBM_SemiBold>
                  ))}
                </Common.FlexRow>
              </>
            )}

            <Common.SizedBoxH height={24} />
            <Styled.NamecardLine />
            <Common.SizedBoxH height={24} />

            <Typo.IBM_Regular fontSize={16} color="#393939" textAlign="center">
              {data?.introduce ?? <>자기소개가 아직 없어요.</>}
            </Typo.IBM_Regular>
          </Styled.NamecardContents>

          <Common.SizedBoxH height={16} />

          <Common.FlexRow alignItems="center" justifyContent="center">
            {id && user && id === user.uid && (
              <>
                <Styled.NamecardButton onClick={() => setEdit(true)}>
                  <Common.SizedImage src={Icons.Edit} width={14} height={14} />
                  <Common.SizedBoxW width={4} />
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite}>
                    프로필 편집
                  </Typo.IBM_SemiBold>
                </Styled.NamecardButton>
                <Common.SizedBoxW width={8} />
              </>
            )}

            <Styled.NamecardButton
              onClick={() => {
                copyText(window.location.href);
                alert('복사 완료!');
              }}
            >
              <Common.SizedImage src={Icons.Link} width={14} height={14} />
              <Common.SizedBoxW width={4} />
              <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite}>
                공유
              </Typo.IBM_SemiBold>
            </Styled.NamecardButton>
          </Common.FlexRow>
        </>
      )}

      {edit && (
        <>
          <input type="file" id="profile-image" onChange={handleProfileFileUpload} ref={inputProfileFile} style={{ display: 'none' }} />
          <input
            type="file"
            id="background-image"
            onChange={handleBackgroundFileUpload}
            ref={inputBackgroundFile}
            style={{ display: 'none' }}
          />

          <Styled.NamecardBackgroundPhotoButton onClick={() => setShowBackgroundImageMenu(!showBackgroundImageMenu)}>
            <Common.SizedImage src={Icons.Photo} width={24} height={24} />
          </Styled.NamecardBackgroundPhotoButton>

          {showBackgroundImageMenu && (
            <OutsideClickHandler onOutsideClick={() => setShowBackgroundImageMenu(false)}>
              <Styled.NamecardBackgroundImageMenu>
                <Common.NoOpacityButton
                  onClick={() => {
                    setShowBackgroundImageMenu(false);
                    console.log('upload background...');
                    onUploadBackgroundImage();
                  }}
                >
                  <Typo.IBM_Regular fontSize={12} color="#393939">
                    사진 수정
                  </Typo.IBM_Regular>
                </Common.NoOpacityButton>
                <Common.NoOpacityButton
                  onClick={() => {
                    setShowBackgroundImageMenu(false);
                    onRemoveBackgroundImage();
                  }}
                >
                  <Typo.IBM_Regular fontSize={12} color="#393939">
                    사진 삭제
                  </Typo.IBM_Regular>
                </Common.NoOpacityButton>
              </Styled.NamecardBackgroundImageMenu>
            </OutsideClickHandler>
          )}

          <Styled.NamecardContents>
            <Styled.NamecardPhoto src={userData?.profile ?? DefaultPhoto} />

            <Styled.NamecardPhotoButton onClick={() => setShowProfileImageMenu(!showProfileImageMenu)}>
              <Common.SizedImage src={Icons.Photo} width={24} height={24} />
            </Styled.NamecardPhotoButton>

            {showProfileImageMenu && (
              <OutsideClickHandler onOutsideClick={() => setShowProfileImageMenu(false)}>
                <Styled.NamecardProfileImageMenu>
                  <Common.NoOpacityButton
                    onClick={() => {
                      setShowProfileImageMenu(false);
                      onUploadProfileImage();
                    }}
                  >
                    <Typo.IBM_Regular fontSize={12} color="#393939">
                      사진 수정
                    </Typo.IBM_Regular>
                  </Common.NoOpacityButton>
                  <Common.NoOpacityButton
                    onClick={() => {
                      setShowProfileImageMenu(false);
                      onRemoveProfileImage();
                    }}
                  >
                    <Typo.IBM_Regular fontSize={12} color="#393939">
                      사진 삭제
                    </Typo.IBM_Regular>
                  </Common.NoOpacityButton>
                </Styled.NamecardProfileImageMenu>
              </OutsideClickHandler>
            )}

            <Common.FlexRow alignItems="center" justifyContent="center">
              <Styled.NamecardEditTabButton disabled={editTab === 'basic'} onClick={() => setEditTab('basic')}>
                기본 정보
              </Styled.NamecardEditTabButton>
              <Common.SizedBoxW width={16} />
              <Styled.NamecardEditTabButton disabled={editTab === 'introduce'} onClick={() => setEditTab('introduce')}>
                자기소개
              </Styled.NamecardEditTabButton>
              <Common.SizedBoxW width={16} />
              <Styled.NamecardEditTabButton disabled={editTab === 'link'} onClick={() => setEditTab('link')}>
                소셜 링크
              </Styled.NamecardEditTabButton>
            </Common.FlexRow>

            <Common.SizedBoxH height={16} />
            <Styled.NamecardLine />
            <Common.SizedBoxH height={24} />

            {editTab === 'basic' && (
              <>
                <Typo.IBM_SemiBold fontSize={16} color="#393939" textAlign="left">
                  유저네임
                </Typo.IBM_SemiBold>
                <Common.SizedBoxH height={8} />

                <Styled.NamecardInput
                  placeholder="다른 멤버들에게 소개할 나의 이름은?"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />

                <Common.SizedBoxH height={24} />

                <Typo.IBM_SemiBold fontSize={16} color="#393939" textAlign="left">
                  멤버십
                </Typo.IBM_SemiBold>
                <Common.SizedBoxH height={8} />

                <Common.FlexRow alignItems="center" justifyContent="flex-start">
                  {membership === 'normal' ? (
                    <>
                      <Typo.IBM_Regular fontSize={14} color="#393939">
                        Wanderer (일반)
                      </Typo.IBM_Regular>

                      <Common.SizedBoxW width={8} />

                      <Common.NoOpacityButton>
                        <Styled.NamecardMembershipTag backgroundColor="#F25C54">
                          <Typo.IBM_SemiBold fontSize={10} color={Colors.neutralWhite}>
                            업그레이드
                          </Typo.IBM_SemiBold>
                        </Styled.NamecardMembershipTag>
                      </Common.NoOpacityButton>
                    </>
                  ) : (
                    <>
                      <Typo.IBM_Regular fontSize={14} color="#393939">
                        Gabbi (유료)
                      </Typo.IBM_Regular>

                      <Common.SizedBoxW width={8} />

                      <Common.NoOpacityButton
                        onClick={() => {
                          window.open(MembershipBenefitsUrl);
                        }}
                      >
                        <Styled.NamecardMembershipTag backgroundColor="#8E8E8E">
                          <Typo.IBM_SemiBold fontSize={10} color={Colors.neutralWhite}>
                            혜택 보러가기
                          </Typo.IBM_SemiBold>
                        </Styled.NamecardMembershipTag>
                      </Common.NoOpacityButton>
                    </>
                  )}
                </Common.FlexRow>

                <Common.SizedBoxH height={24} />

                <Typo.IBM_SemiBold fontSize={16} color="#393939" textAlign="left">
                  하는 일
                </Typo.IBM_SemiBold>
                <Common.SizedBoxH height={8} />

                <Styled.NamecardInput placeholder="현재 나의 신분은?" value={job} onChange={(e) => setJob(e.target.value)} />

                <Common.SizedBoxH height={24} />

                <Typo.IBM_SemiBold fontSize={16} color="#393939" textAlign="left">
                  베이스
                </Typo.IBM_SemiBold>
                <Common.SizedBoxH height={8} />

                <Styled.NamecardInput placeholder="현재 거주하는 곳은?" value={base} onChange={(e) => setBase(e.target.value)} />
              </>
            )}

            {editTab === 'introduce' && (
              <>
                <Typo.IBM_SemiBold fontSize={16} color="#393939" textAlign="left">
                  키워드
                </Typo.IBM_SemiBold>
                <Common.SizedBoxH height={8} />

                <Common.FlexRow alignItems="flex-start" justifyContent="flex-start">
                  {tags?.map((e) => (
                    <>
                      <Typo.IBM_Regular fontSize={14} color="#393939">
                        #{e}
                      </Typo.IBM_Regular>
                      <Common.SizedBoxW width={8} />
                    </>
                  ))}

                  <Common.NoOpacityButton onClick={() => setEditTags(true)}>
                    <Styled.NamecardMembershipTag backgroundColor="#8E8E8E">
                      <Typo.IBM_SemiBold fontSize={10} color={Colors.neutralWhite}>
                        키워드 변경
                      </Typo.IBM_SemiBold>
                    </Styled.NamecardMembershipTag>
                  </Common.NoOpacityButton>
                </Common.FlexRow>

                <Common.SizedBoxH height={24} />

                <Typo.IBM_SemiBold fontSize={16} color="#393939" textAlign="left">
                  자기소개
                </Typo.IBM_SemiBold>
                <Common.SizedBoxH height={8} />

                <Styled.NamecardTextArea
                  placeholder="최대 200자"
                  value={introduce}
                  maxLength={200}
                  onChange={(e) => setIntroduce(e.target.value)}
                />
              </>
            )}

            {editTab === 'link' && (
              <>
                <Typo.IBM_SemiBold fontSize={16} color="#393939" textAlign="left">
                  디스코드 계정
                </Typo.IBM_SemiBold>
                <Common.SizedBoxH height={8} />

                <Common.FlexRow alignItems="center">
                  {userData?.discord ? (
                    <>
                      <Typo.IBM_Regular fontSize={16} color="#393939">
                        연동됨
                      </Typo.IBM_Regular>
                      <Common.SizedBoxW width={8} />
                      <Common.NoOpacityButton
                        onClick={() => {
                          if (!userData.discord) return;
                          window.open(getDiscordProfileUrl(userData.discord));
                        }}
                      >
                        <Styled.NamecardMembershipTag backgroundColor="#8E8E8E">
                          <Typo.IBM_SemiBold fontSize={10} color={Colors.neutralWhite}>
                            계정 확인
                          </Typo.IBM_SemiBold>
                        </Styled.NamecardMembershipTag>
                      </Common.NoOpacityButton>
                      <Common.SizedBoxW width={8} />
                      <Common.NoOpacityButton onClick={() => disconnectDiscord()}>
                        <Styled.NamecardMembershipTag backgroundColor="#8E8E8E">
                          <Typo.IBM_SemiBold fontSize={10} color={Colors.neutralWhite}>
                            연동 해제
                          </Typo.IBM_SemiBold>
                        </Styled.NamecardMembershipTag>
                      </Common.NoOpacityButton>
                    </>
                  ) : (
                    <>
                      <Common.NoOpacityButton
                        onClick={() => {
                          window.open(DiscordOauth2Url);
                        }}
                      >
                        <Styled.NamecardMembershipTag backgroundColor="#8E8E8E">
                          <Typo.IBM_SemiBold fontSize={10} color={Colors.neutralWhite}>
                            연동하기
                          </Typo.IBM_SemiBold>
                        </Styled.NamecardMembershipTag>
                      </Common.NoOpacityButton>
                    </>
                  )}
                </Common.FlexRow>

                <Common.SizedBoxH height={24} />

                <Typo.IBM_SemiBold fontSize={16} color="#393939" textAlign="left">
                  소셜 링크
                </Typo.IBM_SemiBold>
                <Common.SizedBoxH height={8} />

                <Common.FlexRow alignItems="center">
                  <Common.SizedImage src={EmailIcon} width={24} height={20} />
                  <Common.SizedBoxW width={8} />
                  <Styled.NamecardInputWithPrefix>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="sample@email.com" />
                  </Styled.NamecardInputWithPrefix>
                </Common.FlexRow>

                <Common.SizedBoxH height={12} />

                <Common.FlexRow alignItems="center">
                  <Common.SizedImage src={LinkedInIcon} width={24} height={24} />
                  <Common.SizedBoxW width={8} />
                  <Styled.NamecardInputWithPrefix
                    onClick={() => {
                      linkedInRef.current && linkedInRef.current.focus();
                    }}
                  >
                    <Typo.IBM_Regular fontSize={16} color="#8E8E8E">
                      linkedin.com/in/
                    </Typo.IBM_Regular>
                    <input ref={linkedInRef} value={linkedIn} onChange={(e) => setLinkedIn(e.target.value)} />
                  </Styled.NamecardInputWithPrefix>
                </Common.FlexRow>

                <Common.SizedBoxH height={12} />

                <Common.FlexRow alignItems="center">
                  <Common.SizedImage src={InstagramIcon} width={24} height={24} />
                  <Common.SizedBoxW width={8} />
                  <Styled.NamecardInputWithPrefix
                    onClick={() => {
                      instagramRef.current && instagramRef.current.focus();
                    }}
                  >
                    <Typo.IBM_Regular fontSize={16} color="#8E8E8E">
                      instagram.com/
                    </Typo.IBM_Regular>
                    <input ref={instagramRef} value={instagram} onChange={(e) => setInstagram(e.target.value)} />
                  </Styled.NamecardInputWithPrefix>
                </Common.FlexRow>

                <Common.SizedBoxH height={12} />

                <Common.FlexRow alignItems="center">
                  <Common.SizedImage src={HomepageIcon} width={24} height={24} />
                  <Common.SizedBoxW width={8} />
                  <Styled.NamecardInputWithPrefix>
                    <input value={webSite} onChange={(e) => setWebSite(e.target.value)} />
                  </Styled.NamecardInputWithPrefix>
                </Common.FlexRow>
              </>
            )}
          </Styled.NamecardContents>

          <Common.SizedBoxH height={16} />

          <Common.FlexRow justifyContent="center">
            <Styled.NamecardEditCancelButton onClick={() => onCancelEdit()}>취소</Styled.NamecardEditCancelButton>
            <Common.SizedBoxW width={8} />
            <Styled.NamecardEditConfirmButton disabled={!activateEditButton} onClick={() => onSubmitEdit()}>
              저장
            </Styled.NamecardEditConfirmButton>
          </Common.FlexRow>
        </>
      )}

      {edit && editTags && (
        <Styled.NamecardEditTags>
          <Styled.NamecardEditTagsContainer>
            <Common.FlexRow alignItems="center">
              <Typo.IBM_SemiBold fontSize={14} color="#393939">
                관심 키워드
              </Typo.IBM_SemiBold>
              <Common.SizedBoxW width={4} />
              <Typo.IBM_SemiBold fontSize={12} color="#8E8E8E">
                (최대 3개)
              </Typo.IBM_SemiBold>
            </Common.FlexRow>

            <Common.SizedBoxH height={24} />

            <Styled.NamecardEditTagsToggleContainer>
              {tagDatas.map((e) => (
                <Styled.NamecardEditTagsToggle
                  isOn={tagsTemp.includes(e)}
                  onClick={() =>
                    setTagsTemp((data) => {
                      if (!data.includes(e)) data.push(e);
                      else data.splice(data.indexOf(e), 1);

                      if (data.length > 3) data.splice(0, data.length - 3);

                      return [...data];
                    })
                  }
                >
                  {e}
                </Styled.NamecardEditTagsToggle>
              ))}
            </Styled.NamecardEditTagsToggleContainer>

            <Common.SizedBoxH height={24} />
            <Common.FlexRow alignItems="center" justifyContent="center">
              <Styled.NamecardEditCancelButton
                onClick={() => {
                  setTagsTemp([...(tags ?? [])]);
                  setEditTags(false);
                }}
              >
                취소
              </Styled.NamecardEditCancelButton>
              <Common.SizedBoxW width={8} />

              <Styled.NamecardEditConfirmButton
                onClick={() => {
                  setTags([...tagsTemp]);
                  setEditTags(false);
                }}
                disabled={JSON.stringify(tagsTemp ?? []) === JSON.stringify(tags ?? [])}
              >
                선택 완료
              </Styled.NamecardEditConfirmButton>
            </Common.FlexRow>
          </Styled.NamecardEditTagsContainer>
        </Styled.NamecardEditTags>
      )}
    </Styled.NamecardContainer>
  );
}

export default Namecard;
