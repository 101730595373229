import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Urls from 'common/urls';
import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Styled from './main.style';

import { getPosts } from 'api/ghost-content-api';
import { Colors } from 'common';
import Header from 'components/header';
import dayjs from 'dayjs';
import { animated, useSpring } from 'react-spring';
import { FirebaseContext } from 'context/firebaseContext';
import { Link } from 'react-router-dom';
import { CommonImages, Icons } from 'common/images';
import Footer from 'components/footer';

import PageLeftIcon from 'img/main/page_left.png';
import PageRightIcon from 'img/main/page_right.png';
import { needEmailVerification } from 'common/utils';

function Main(): ReactElement {
  const { user, userData } = useContext(FirebaseContext);

  const [blogPosts, setBlogPosts] = useState<BlogPostDto[]>([]);
  const [postIndex, setPostIndex] = useState(0);
  const [showAskMembership, setShowAskMembership] = useState(false);

  useEffect(() => {
    (async () => {
      const response: BlogPostDto[] = await getPosts(4);
      setBlogPosts(response);
    })();
  }, []);

  useEffect(() => {
    if (!user) return;

    if (needEmailVerification(user)) {
      window.location.replace('/email-verify');
      return;
    }

    if (userData === undefined) return;

    if (!userData) {
      window.location.replace('/select-membership');
      return;
    }

    if (userData !== undefined) {
      if (userData === null || !userData.membership) {
        setShowAskMembership(true);
      }
    }
  }, [user, userData]);

  useEffect(() => {
    if (blogPosts.length == 0) return;

    const interval = setInterval(() => setPostIndex((i) => (i + 1) % blogPosts.length), 5000);
    return () => {
      clearInterval(interval);
    };
  }, [blogPosts]);

  return (
    <>
      {user === undefined ? (
        <></>
      ) : (
        <>
          <Header />
          <Styled.MainContainer>
            <Common.SizedBoxH height={15} />
            <Common.NoOpacityButton
              onClick={() => {
                window.open(blogPosts[postIndex]?.url);
              }}
            >
              <Styled.MainBackground>
                <Styled.MainBackgroundTexts>
                  <Common.FlexRow alignItems="center">
                    <Typo.Yangjin fontSize={18} color="#FBC3BC">
                      0{postIndex + 1}
                    </Typo.Yangjin>
                    <Common.SizedBoxW width={6} />
                    <Typo.Yangjin fontSize={14} color="#DCDCDC">
                      /
                    </Typo.Yangjin>
                    <Common.SizedBoxW width={4} />
                    <Typo.Yangjin fontSize={14} color="#DCDCDC">
                      0{blogPosts.length}
                    </Typo.Yangjin>
                  </Common.FlexRow>

                  <Common.SizedBoxH height={24} />
                  <Typo.Yangjin width={800} fontSize={28} color="#FBC3BC" textAlign="right">
                    {blogPosts[postIndex]?.title}
                  </Typo.Yangjin>
                  <Common.SizedBoxH height={24} />
                  <Typo.IBM_Regular width={530} fontSize={16} color="#F8F8F8" textAlign="right">
                    {blogPosts[postIndex]?.excerpt}
                  </Typo.IBM_Regular>
                </Styled.MainBackgroundTexts>

                <Styled.MainBackgroundImages count={blogPosts.length} index={postIndex}>
                  {blogPosts.map((e) => (
                    <Styled.MainBackgroundImage src={e.feature_image} />
                  ))}
                </Styled.MainBackgroundImages>
              </Styled.MainBackground>
            </Common.NoOpacityButton>

            <Common.SizedBoxH height={24} />

            <Common.FlexRow height={45}>
              <Styled.MainPageButton
                onClick={() =>
                  setPostIndex((i) => {
                    i--;
                    return i < 0 ? blogPosts.length - 1 : i;
                  })
                }
              >
                <Common.SizedImage width={7.7} height={12} src={PageLeftIcon} />
              </Styled.MainPageButton>
              <Common.SizedBoxW width={12} />
              <Styled.MainPageButton onClick={() => setPostIndex((i) => (i + 1) % blogPosts.length)}>
                <Common.SizedImage width={7.7} height={12} src={PageRightIcon} />
              </Styled.MainPageButton>
            </Common.FlexRow>
          </Styled.MainContainer>

          <Footer />

          {showAskMembership && (
            <Styled.MainAskMembership>
              <Styled.MainAskMembershipWindow>
                <Common.SizedImage src={CommonImages.Logo} width={32.89} height={36} />
                <Common.SizedBoxH height={24} />
                <Typo.IBM_Regular fontSize={14} color={Colors.neutralBlack}>
                  앗! 멤버 가입이 완료되지 않았어요.
                  <br />
                  멤버십을 선택하러 가시겠어요?
                </Typo.IBM_Regular>
                <Common.SizedBoxH height={24} />
                <Styled.MainAskMembershipButton
                  onClick={() => {
                    window.location.replace('/select-membership');
                  }}
                >
                  멤버십 선택
                </Styled.MainAskMembershipButton>
              </Styled.MainAskMembershipWindow>
            </Styled.MainAskMembership>
          )}
        </>
      )}
    </>
  );
}

export default Main;
