import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { sendEmailVerification } from 'firebase/auth';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Styled from './events.style';

import { Colors } from 'common';
import { Icons } from 'common/images';
import { needEmailVerification } from 'common/utils';
import { FirebaseContext } from 'context/firebaseContext';

import * as Database from 'firebase/database';
import { db } from 'services/firebase';
import Header from 'components/header';
import EventCard from './eventCard';
import Footer from 'components/footer';
import { EventHostingUrl } from 'common/urls';

function Events(): ReactElement {
  const { user, userData } = useContext(FirebaseContext);
  const [events, setEvents] = useState<{ [key: string]: EventDto }>({});

  useEffect(() => {
    (async () => {
      try {
        const dbRef = Database.ref(db, 'events');
        const snapshot = await Database.get(dbRef);
        setEvents(snapshot.val());
      } catch (error) {
        alert(error);
      }
    })();
  }, []);

  return (
    <>
      <Header />

      <Styled.EventsContainer>
        <Styled.EventsBackground />

        <Common.SizedBoxH height={64} />

        <Typo.Nanum_Hipi fontSize={24} color="#393939">
          “전 세계 노마드들과 만나 시야를 넓혀보세요.”
        </Typo.Nanum_Hipi>

        <Common.SizedBoxH height={36} />

        <Styled.EventsHostButton
          onClick={() => {
            if (user === undefined) return;
            if (user === null) {
              alert('멤버만 이용할 수 있는 기능입니다!');
              return;
            }
            window.open(EventHostingUrl);
          }}
        >
          <Common.SizedImage src={Icons.EventHost} width={14} height={12.444} />
          <Common.SizedBoxW width={4} />
          <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite}>
            이벤트 호스팅하기
          </Typo.IBM_SemiBold>
        </Styled.EventsHostButton>

        <Common.SizedBoxH height={128} />

        <Styled.EventsEventCardsContainer>
          {Object.keys(events).map((e) => (
            <Link to={`/event-detail/${e}`} key={e}>
              <EventCard data={events[e]} />
            </Link>
          ))}
        </Styled.EventsEventCardsContainer>

        <Common.SizedBoxH height={50} />
      </Styled.EventsContainer>

      <Footer />
    </>
  );
}

export default Events;
