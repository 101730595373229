import GhostContentAPI from '@tryghost/content-api';

const url = 'https://nomad-in-seoul.ghost.io';
const key = '8720e33b1aa345b4b16009f37c';

const api = new GhostContentAPI({
  url,
  key,
  version: 'v5.0',
});

export async function getPosts(count?: number): Promise<any> {
  try {
    const response = await api.posts.browse({
      limit: count ?? 3,
      include: ['authors', 'tags'],
      filter: 'featured:true',
    });
    console.log(response);
    return response;
  } catch (error) {
    throw error;
  }
}
