import React, { ReactElement, useContext, useState } from 'react';
import { FirebaseContext } from 'context/firebaseContext';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from 'services/firebase';

import * as Styled from './footer.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { CommonImages, Icons } from 'common/images';
import { Colors } from 'common';
import OutsideClickHandler from 'react-outside-click-handler';
import { Blogs, PrivacyPolicyUrl, TermsUrl } from 'common/urls';

import EmailIcon from 'img/footer/footer_mail.png';
import DiscordIcon from 'img/footer/footer_discord.png';
import InstagramIcon from 'img/footer/footer_instagram.png';

function Footer(): ReactElement {
  return (
    <Styled.FooterContainer>
      {/* <Common.FlexRow alignItems="center" justifyContent="center">
        <Common.NoOpacityButton onClick={() => window.open('mailto:nomadinseoul@gmail.com')}>
          <Common.SizedImage src={EmailIcon} width={24} height={20} />
        </Common.NoOpacityButton>

        <Common.SizedBoxW width={36} />

        <Common.NoOpacityButton onClick={() => window.open('https://instagram.com/nomad_in_seoul')}>
          <Common.SizedImage src={InstagramIcon} width={20} height={20} />
        </Common.NoOpacityButton>

        <Common.SizedBoxW width={36} />

        <Common.NoOpacityButton onClick={() => window.open('https://discord.gg/xU6pdwUxRA')}>
          <Common.SizedImage src={DiscordIcon} width={24} height={18.19} />
        </Common.NoOpacityButton>
      </Common.FlexRow>

      <Common.SizedBoxH height={36} /> */}

      <Common.SizedBoxH height={64} />

      <Common.FlexRow alignItems="center" justifyContent="center">
        <Typo.IBM_Regular fontSize={12} color="#8E8E8E">
          © 2023 노마드인서울
        </Typo.IBM_Regular>

        <Common.SizedBoxW width={16} />

        <Common.NoOpacityButton onClick={() => window.open(TermsUrl)}>
          <Typo.IBM_Regular fontSize={12} color="#8E8E8E">
            이용약관
          </Typo.IBM_Regular>
        </Common.NoOpacityButton>

        <Common.SizedBoxW width={16} />

        <Common.NoOpacityButton onClick={() => window.open(PrivacyPolicyUrl)}>
          <Typo.IBM_Regular fontSize={12} color="#8E8E8E">
            개인정보처리방침
          </Typo.IBM_Regular>
        </Common.NoOpacityButton>
      </Common.FlexRow>
    </Styled.FooterContainer>
  );
}

export default Footer;
