import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { sendEmailVerification } from 'firebase/auth';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from './eventCard.style';

import { Colors } from 'common';
import { Icons } from 'common/images';
import { needEmailVerification } from 'common/utils';
import { FirebaseContext } from 'context/firebaseContext';

import * as Database from 'firebase/database';
import { db } from 'services/firebase';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { getUserByUid } from 'api/firebase-api';

function EventCard(props: { data: EventDto }): ReactElement {
  const { data } = props;
  const [host, setHost] = useState<UserDto[]>([]);

  useEffect(() => {
    (async () => {
      if (!data.hosts) return;

      var host = await Promise.all(data.hosts.map((e) => getUserByUid(e)));

      setHost(host);
    })();
  }, []);

  return (
    <Common.FlexColumn width={526} alignItems="stretch" justifyContent="center">
      <Common.FlexRow alignItems="flex-start" justifyContent="space-between">
        <Typo.Yangjin fontSize={18} textAlign="left" color={Colors.neutralBlack}>
          {data.title}
        </Typo.Yangjin>

        <Common.FlexColumn alignItems="flex-end" justifyContent="flex-start">
          <Typo.Yangjin fontSize={14} color="#8E8E8E">
            {data.date && dayjs(data.date).locale('ko').format('YY.MM.DD (ddd) h:mm A')}
          </Typo.Yangjin>
          <Common.SizedBoxH height={8} />
          <Typo.Yangjin fontSize={14} color="#8E8E8E">
            {data.location}
          </Typo.Yangjin>
        </Common.FlexColumn>
      </Common.FlexRow>

      <Common.SizedBoxH height={36} />

      <Common.FlexRow alignItems="center" justifyContent="flex-start">
        <Typo.IBM_SemiBold fontSize={16} color="#393939">
          Hosted by:
        </Typo.IBM_SemiBold>
        {host &&
          Object.values(host).map((e, i) => (
            <Typo.IBM_Regular fontSize={16} color="#393939">
              &nbsp;{e.username}
              {data.hosts && i < Object.values(data.hosts).length - 1 && <>,</>}
            </Typo.IBM_Regular>
          ))}
      </Common.FlexRow>

      <Common.SizedBoxH height={4} />

      <Typo.IBM_Regular fontSize={16} color="#393939" textAlign="left">
        {data.description}
      </Typo.IBM_Regular>

      <Common.SizedBoxH height={36} />

      <Styled.ImageContainer>
        <Styled.Image src={data.thumbnail} />
      </Styled.ImageContainer>
    </Common.FlexColumn>
  );
}

export default EventCard;
