import { User } from 'firebase/auth';
import { useContext } from 'react';

const DiscordCdnUrl = 'https://cdn.discordapp.com';
const DiscordAppUrl = 'https://discordapp.com';

export function getDiscordAvaterImageUrl(data: DiscordUserDto): string {
  if (data.avatar) return `${DiscordCdnUrl}/avatars/${data.id}/${data.avatar}.png`;
  else return '';
}

export function getDiscordProfileUrl(userId: string): string {
  return `${DiscordAppUrl}/users/${userId}`;
}

export function needEmailVerification(user: User): boolean {
  return user.providerData.length == 1 && user.providerData[0].providerId == 'password' && !user.emailVerified;
}

export function needSetProfile(userData: UserDto | null): boolean {
  if (!userData) return true;
  if (!userData.username || !userData.tags) return true;
  return false;
}

export function copyText(text: string) {
  const textarea = document.createElement('textarea');
  textarea.value = text;

  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
}
