import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

import BackgroundImage from 'img/background/Background_0.png';

export const AgitsContainer = styled.div`
  width: 100%;
  min-height: 764px;
  height: 100vh;

  background-image: linear-gradient(
      0deg,
      #fff 46.58%,
      rgba(255, 255, 255, 0.7) 69.79%,
      rgba(255, 255, 255, 0.7) 100%,
      #fff 100%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(${BackgroundImage});
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AgitsButton = styled.button`
  padding: 12px 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background: var(--P5, #f25c54);

  border: none;
`;

export const AgitsImageContainer = styled.div`
  width: 710px;
  height: 305px;
  border-radius: 36px;

  overflow: hidden;
`;
