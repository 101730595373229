import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

export const ImageContainer = styled.div`
  width: 526px;
  height: 328px;

  border-radius: 36px;
  background: lightgray 50% / cover no-repeat;

  position: relative;
  overflow: hidden;
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
`;
