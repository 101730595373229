import axios from 'axios';
import * as Auth from 'firebase/auth';
import * as Database from 'firebase/database';
import { auth, db } from 'services/firebase';

const Host = 'https://us-central1-nomad-in-seoul.cloudfunctions.net';

export async function signup(email: string, password: string): Promise<Auth.UserCredential> {
  const emailValue = (email ?? '').trim();
  const passwordValue = (password ?? '').trim();

  if (!emailValue) {
    throw new Error('이메일을 입력해주세요.');
  }

  if (!passwordValue) {
    throw new Error('비밀번호를 입력해주세요.');
  }

  try {
    const userCredential = await Auth.createUserWithEmailAndPassword(auth, emailValue, passwordValue);
    return userCredential;
  } catch (error) {
    throw error;
  }
}

export async function signin(email: string, password: string): Promise<Auth.UserCredential> {
  const emailValue = (email ?? '').trim();
  const passwordValue = (password ?? '').trim();

  if (!emailValue) {
    throw new Error('이메일을 입력해주세요.');
  }

  if (!passwordValue) {
    throw new Error('비밀번호를 입력해주세요.');
  }

  try {
    await Auth.setPersistence(auth, Auth.browserLocalPersistence);
    const userCredential = await Auth.signInWithEmailAndPassword(auth, emailValue, passwordValue);
    return userCredential;
  } catch (error) {
    throw error;
  }
}

export async function signWithGoogle(): Promise<Auth.UserCredential> {
  const provider = new Auth.GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  auth.languageCode = 'KR';

  try {
    const userCredential = await Auth.signInWithPopup(auth, provider);
    return userCredential;
  } catch (error) {
    throw error;
  }
}

export async function sendEmail(user: Auth.User): Promise<void> {
  if (!user) return;

  try {
    await Auth.sendEmailVerification(user, {
      url: 'https://nomadinseoul.com',
    });
  } catch (error) {
    throw error;
  }
}

export async function sendPasswordResetEmail(email: string): Promise<void> {
  try {
    await Auth.sendPasswordResetEmail(auth, email);

    // alert('이메일이 성공적으로 전송되었습니다!');
    // window.location.replace('/sign-in');
  } catch (error) {
    throw error;
  }
}

export async function setDiscordAccount(user: Auth.User, params: { code: string; redirectUri: string }): Promise<DiscordUserDto> {
  const { code, redirectUri } = params;

  try {
    const token = await Auth.getIdToken(user);

    const response = await axios.post(
      `${Host}/discord/${user.uid}`,
      {
        code: code,
        redirect_uri: redirectUri,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status !== 201) throw response.status;

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function disconnectDiscordAccount(user: Auth.User): Promise<void> {
  try {
    const token = await Auth.getIdToken(user);

    const response = await axios.delete(`${Host}/discord/${user.uid}`);

    if (response.status !== 200) throw response.status;

    return;
  } catch (error) {
    throw error;
  }
}

export async function testPaymentAuth(): Promise<PaymentAuthResponseDto> {
  try {
    // const token = await getIdToken(user);

    const response = await axios.post(
      `${Host}/payple/test/auth`,
      {
        referer: window.location.origin,
      },
      // {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUserByUid(uid: string): Promise<UserDto> {
  try {
    const dbRef = Database.ref(db, `users/${uid}`);
    const snapshot = await Database.get(dbRef);
    return snapshot.val();
  } catch (error) {
    throw error;
  }
}
