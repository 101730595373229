import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { auth, db } from 'services/firebase';
import * as Styled from './selectMembership.style';

import { Colors } from 'common';
import { toast } from 'react-toastify';
import validator from 'validator';
import { CommonImages, Icons, MembershipIcons, PremiumMembershipBenefitIcons } from 'common/images';

import * as Database from 'firebase/database';

import MemberhsipDetailIcon from 'img/membership/Out.png';
import { FirebaseContext } from 'context/firebaseContext';
import { purchaseMembership } from 'api/payple-api';

import Confetti from 'react-confetti';
import { MembershipBenefitsUrl } from 'common/urls';

const premiumMembershipBenefits = [
  '발리 2박3일 워케이션',
  '해외 취업 컨설팅',
  '전국 노마드 아지트 이용권',
  'NFC 네임카드 & 다이어리 증정',
  '노마드 이벤트 호스팅 & 매거진 집필 기회',
  '노마드 커피챗',
];

function SelectMembership(): ReactElement {
  const [membership, setMembership] = useState<MembershipType>();
  const [membershipState, setMembershipState] = useState<string>();
  const { user, userData } = useContext(FirebaseContext);

  useEffect(() => {
    if (user === undefined) return;
    if (user === null) {
      window.location.replace('/');
      return;
    }
  }, [user, userData]);

  async function onConfirmMembership() {
    if (!membership) return;
    if (!user) return;

    if (membership === 'normal') {
      try {
        setMembershipState('pending');
        const dbRef = Database.ref(db, `users/${user.uid}`);
        await Database.update(dbRef, {
          membership: 'normal',
        });
        setMembershipState('success');
      } catch (error: any) {
        alert(error.message);
        setMembershipState('failed');
      }
    }
    if (membership === 'premium') {
      try {
        purchaseMembership(user, (e) => {
          console.log(e);
        });
      } catch (error) {
        alert(error);
      }
    }
  }

  return (
    <>
      {!userData?.membership && !membership && (
        <Styled.SelectMembershipContainer>
          <Common.SizedBoxH height={83} />
          <Typo.Yangjin fontSize={54} color="#F25C54">
            Choose your journey
          </Typo.Yangjin>
          <Common.SizedBoxH height={44} />
          <Typo.IBM_Regular fontSize={16} color={Colors.neutralBlack}>
            나에게 맞는 멤버십을 찾아보아요. 무엇을 찾아 여기까지 오셨나요?
          </Typo.IBM_Regular>

          <Common.SizedBoxH height={32} />
          <Common.FlexRow alignItems="center" justifyContent="center">
            <Styled.SelectMembershipCard onClick={() => setMembership('normal')}>흠, 아직 잘 모르겠어요.</Styled.SelectMembershipCard>

            <Common.SizedBoxW width={36} />

            <Styled.SelectMembershipCard onClick={() => setMembership('premium')}>노마드가 되고 싶어요.</Styled.SelectMembershipCard>
          </Common.FlexRow>
        </Styled.SelectMembershipContainer>
      )}

      {!userData?.membership && membership === 'normal' && (
        <Styled.NormalMemberContainer>
          <Common.SizedBoxH height={83} />
          <Typo.Yangjin fontSize={54} color="#F25C54">
            You are...
          </Typo.Yangjin>
          <Common.SizedBoxH height={60} />

          <Common.FlexRow alignItems="center" justifyContent="center">
            <Styled.NormalMemberCard>
              <Styled.NormalMemberCardContents>
                <Typo.Yangjin fontSize={18} color={Colors.neutralWhite}>
                  WANDERER
                </Typo.Yangjin>

                <Common.SizedBoxH height={24} />

                <Typo.IBM_SemiBold fontSize={16} color={Colors.neutralWhite}>
                  일반 멤버십
                </Typo.IBM_SemiBold>

                <Common.SizedBoxH height={6} />

                <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite}>
                  FREE
                </Typo.IBM_SemiBold>
              </Styled.NormalMemberCardContents>
            </Styled.NormalMemberCard>

            <Common.SizedBoxW width={112} />
            <Common.FlexColumn alignItems="center" justifyContent="center">
              <Typo.Nanum_Hipi fontSize={24} color="#393939">
                “노마드가 뭐에요? 일단 먼저 들어볼래요.”
              </Typo.Nanum_Hipi>

              <Common.SizedBoxH height={64} />

              <Common.FlexRow alignItems="center" justifyContent="center">
                <Common.SizedImage src={MembershipIcons.MembershipKakaoTalk} width={18} height={18} />
                <Common.SizedBoxW width={8} />

                <Typo.IBM_SemiBold fontSize={14} color="#393939">
                  노마드 단톡방 입장
                </Typo.IBM_SemiBold>
              </Common.FlexRow>

              <Common.SizedBoxH height={8} />

              <Common.FlexRow alignItems="center" justifyContent="center">
                <Common.SizedImage src={MembershipIcons.MembershipDiscord} width={18} />
                <Common.SizedBoxW width={8} />
                <Typo.IBM_SemiBold fontSize={14} color="#393939">
                  디스코드 마을 초대
                </Typo.IBM_SemiBold>
              </Common.FlexRow>

              <Common.SizedBoxH height={8} />

              <Common.FlexRow alignItems="center" justifyContent="center">
                <Common.SizedImage src={MembershipIcons.MembershipSubscribe} width={18} height={16} />
                <Common.SizedBoxW width={8} />
                <Typo.IBM_SemiBold fontSize={14} color="#393939">
                  노마드 뉴스레터 구독
                </Typo.IBM_SemiBold>
              </Common.FlexRow>

              <Common.SizedBoxH height={64} />

              <Common.FlexRow alignItems="center" justifyContent="center">
                <Typo.IBM_Regular fontSize={14} color="#393939">
                  노마드가 되는 데 관심이 있나요?
                </Typo.IBM_Regular>
                <Common.SizedBoxW width={12} />
                <Common.SizedImage src={CommonImages.Logo} width={14} height={15} objectFit="contain" />
                <Common.SizedBoxW width={4} />
                <Common.NoOpacityButton onClick={() => setMembership('premium')}>
                  <Typo.IBM_SemiBold fontSize={12} color="#8E8E8E" textDecoration="underline">
                    깨비 멤버십 보기
                  </Typo.IBM_SemiBold>
                </Common.NoOpacityButton>
              </Common.FlexRow>
            </Common.FlexColumn>
          </Common.FlexRow>

          <Common.SizedBoxH height={58} />

          <Common.FlexRow>
            <Styled.SelectMemberPrevButton onClick={() => setMembership(undefined)}>이전으로</Styled.SelectMemberPrevButton>
            <Common.SizedBoxW width={8} />
            <Styled.SelectMemberNextButton onClick={onConfirmMembership}>선택</Styled.SelectMemberNextButton>
          </Common.FlexRow>
        </Styled.NormalMemberContainer>
      )}

      {!userData?.membership && membership === 'premium' && (
        <Styled.PremiumMemberContainer>
          <Common.SizedBoxH height={83} />
          <Typo.Yangjin fontSize={54} color="#F25C54">
            You are...
          </Typo.Yangjin>
          <Common.SizedBoxH height={60} />

          <Common.FlexRow alignItems="center" justifyContent="center">
            <Styled.PremiumMemberCard>
              <Styled.PremiumMemberCardContents>
                <Typo.Yangjin fontSize={18} color={Colors.neutralWhite}>
                  GABBI
                </Typo.Yangjin>

                <Common.SizedBoxH height={24} />

                <Typo.IBM_SemiBold fontSize={16} color={Colors.neutralWhite}>
                  노마드 멤버십
                </Typo.IBM_SemiBold>

                <Common.SizedBoxH height={6} />

                <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite}>
                  ₩199,000 / 평생
                </Typo.IBM_SemiBold>
              </Styled.PremiumMemberCardContents>
            </Styled.PremiumMemberCard>

            <Common.SizedBoxW width={112} />

            <Common.FlexColumn alignItems="center" justifyContent="center">
              <Typo.Nanum_Hipi fontSize={24} color="#393939">
                “노마드 해보고 싶어요! 어떻게 시작하면 될까요?”
              </Typo.Nanum_Hipi>

              <Common.SizedBoxH height={48} />

              <Common.FlexRow alignItems="center" justifyContent="center">
                <Common.FlexColumn alignItems="center" justifyContent="center">
                  <Common.FlexRow alignItems="center" justifyContent="center">
                    <Common.SizedImage src={MembershipIcons.MembershipKakaoTalk} width={18} height={18} />
                    <Common.SizedBoxW width={8} />

                    <Typo.IBM_SemiBold fontSize={14} color="#393939">
                      노마드 단톡방 입장
                    </Typo.IBM_SemiBold>
                  </Common.FlexRow>

                  <Common.SizedBoxH height={8} />

                  <Common.FlexRow alignItems="center" justifyContent="center">
                    <Common.SizedImage src={MembershipIcons.MembershipDiscord} width={18} />
                    <Common.SizedBoxW width={8} />
                    <Typo.IBM_SemiBold fontSize={14} color="#393939">
                      디스코드 마을 초대
                    </Typo.IBM_SemiBold>
                  </Common.FlexRow>

                  <Common.SizedBoxH height={8} />

                  <Common.FlexRow alignItems="center" justifyContent="center">
                    <Common.SizedImage src={MembershipIcons.MembershipSubscribe} width={18} height={16} />
                    <Common.SizedBoxW width={8} />
                    <Typo.IBM_SemiBold fontSize={14} color="#393939">
                      노마드 뉴스레터 구독
                    </Typo.IBM_SemiBold>
                  </Common.FlexRow>
                </Common.FlexColumn>
                <Common.SizedBoxW width={36} />

                <Common.SizedImage src={Icons.AddBlack} width={14} height={15} />

                <Common.SizedBoxW width={36} />

                <Styled.PremiumMembershipBenefits>
                  <Styled.PremiumMembershipBenefitsTitle>
                    <Common.SizedImage src={CommonImages.Logo} width={14} height={15.324} />
                    <Common.SizedBoxW width={6} />
                    <Typo.IBM_SemiBold fontSize={14} color="#F25C54">
                      NOMAD STARTER PACKAGE
                    </Typo.IBM_SemiBold>
                  </Styled.PremiumMembershipBenefitsTitle>

                  {premiumMembershipBenefits.map((e, i) => (
                    <>
                      <Common.SizedBoxH height={4} />
                      <Common.FlexRow alignItems="center" justifyContent="center">
                        <Common.SizedImage src={PremiumMembershipBenefitIcons[i]} width={18} height={16} />
                        <Common.SizedBoxW width={8} />
                        <Typo.IBM_SemiBold fontSize={14} color="#393939">
                          {e}
                        </Typo.IBM_SemiBold>
                      </Common.FlexRow>
                      <Common.SizedBoxH height={4} />
                    </>
                  ))}

                  <Common.SizedBoxH height={20} />

                  <Common.NoOpacityButton onClick={() => window.open(MembershipBenefitsUrl)}>
                    <Common.FlexRow alignItems="center" justifyContent="center">
                      <Typo.IBM_SemiBold fontSize={12} color="#8E8E8E" textDecoration="underline">
                        혜택 자세히 보기
                      </Typo.IBM_SemiBold>
                      <Common.SizedBoxW width={4} />
                      <Common.SizedImage src={MemberhsipDetailIcon} width={10} height={10.526} />
                    </Common.FlexRow>
                  </Common.NoOpacityButton>
                </Styled.PremiumMembershipBenefits>
              </Common.FlexRow>
            </Common.FlexColumn>
          </Common.FlexRow>

          <Common.SizedBoxH height={48} />

          <Common.FlexRow>
            <Styled.SelectMemberPrevButton onClick={() => setMembership(undefined)}>이전으로</Styled.SelectMemberPrevButton>
            <Common.SizedBoxW width={8} />
            <Styled.SelectMemberNextButton onClick={onConfirmMembership}>선택</Styled.SelectMemberNextButton>
          </Common.FlexRow>
        </Styled.PremiumMemberContainer>
      )}

      {!userData?.membership && membershipState === 'pending' && membership === 'normal' && (
        <Styled.NormalMemberContainer>
          <Common.SizedBoxH height={83} />
          <Typo.Yangjin fontSize={54} color="#F25C54">
            Almost there
          </Typo.Yangjin>
        </Styled.NormalMemberContainer>
      )}

      {!userData?.membership && membershipState === 'pending' && membership === 'premium' && <></>}

      {userData?.membership && (
        <Styled.SelectMemberFinish membership={userData.membership}>
          <Confetti width={window.innerWidth} height={window.innerHeight} />

          <Typo.Yangjin fontSize={54} color="#F25C54" textAlign="center">
            Congrats!
            <br />
            You’re now one of us
          </Typo.Yangjin>

          <Common.SizedBoxH height={90} />

          <Typo.IBM_Regular fontSize={18} color="#1B1B1B">
            노마드인서울의 멤버가 되신 것을 축하해요!
          </Typo.IBM_Regular>

          <Common.FlexRow>
            <Typo.IBM_SemiBold fontSize={18} color="#1B1B1B">
              노마드인서울 오픈채팅방
            </Typo.IBM_SemiBold>
            <Typo.IBM_Regular fontSize={18} color="#1B1B1B">
              과&nbsp;
            </Typo.IBM_Regular>
            <Typo.IBM_SemiBold fontSize={18} color="#1B1B1B">
              디스코드 서버 초대장
            </Typo.IBM_SemiBold>
            <Typo.IBM_Regular fontSize={18} color="#1B1B1B">
              을 이메일로 보내드렸어요.
            </Typo.IBM_Regular>
          </Common.FlexRow>

          <Common.SizedBoxH height={48} />

          <Common.FlexRow>
            <Typo.IBM_Regular fontSize={18} color="#1B1B1B">
              마지막으로 멤버들에게&nbsp;
            </Typo.IBM_Regular>
            <Typo.IBM_SemiBold fontSize={18} color="#1B1B1B">
              {user?.email}
            </Typo.IBM_SemiBold>
            <Typo.IBM_Regular fontSize={18} color="#1B1B1B">
              &nbsp;님을
            </Typo.IBM_Regular>
          </Common.FlexRow>
          <Typo.IBM_Regular fontSize={18} color="#1B1B1B">
            소개할 프로필을 만들어 보시겠어요?
          </Typo.IBM_Regular>

          <Common.SizedBoxH height={48} />

          <Common.FlexRow alignItems="center" justifyContent="center">
            <Styled.SelectMemberPrevButton onClick={() => window.location.replace('/')}>나중에 할게요</Styled.SelectMemberPrevButton>
            <Common.SizedBoxW width={8} />
            <Styled.SelectMemberNextButton onClick={() => window.location.replace(`/namecard/${user?.uid}`)}>
              네, 좋아요!
            </Styled.SelectMemberNextButton>
          </Common.FlexRow>
        </Styled.SelectMemberFinish>
      )}
    </>
  );
}

export default SelectMembership;
