import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

import BackgroundImage from 'img/background/Background_0.png';

export const EventsContainer = styled.div`
  width: 100%;
  min-height: 764px;

  padding: 72px 0 0 0;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EventsBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 764px;

  background-image: linear-gradient(
      0deg,
      #fff 46.58%,
      rgba(255, 255, 255, 0.7) 69.79%,
      rgba(255, 255, 255, 0.7) 100%,
      #fff 100%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(${BackgroundImage});
  background-size: cover;
  background-position: center;

  z-index: -1;
`;

export const EventsHostButton = styled.button`
  padding: 12px 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background: var(--P5, #f25c54);

  border: none;
`;

export const EventsEventCardsContainer = styled.div`
  width: 1160px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 128px 108px;
`;
