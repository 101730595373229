import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { sendEmailVerification } from 'firebase/auth';
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Styled from './eventDetail.style';

import { Colors } from 'common';
import { CommonImages, Icons } from 'common/images';
import { needEmailVerification } from 'common/utils';
import { FirebaseContext } from 'context/firebaseContext';

import * as Database from 'firebase/database';
import { db } from 'services/firebase';
import Header from 'components/header';
import dayjs from 'dayjs';
import { getUserByUid } from 'api/firebase-api';
import { purchaseEvent } from 'api/payple-api';

import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Footer from 'components/footer';

function EventDetail(): ReactElement {
  const { id } = useParams();

  const [data, setData] = useState<EventDto>();
  const [hosts, setHosts] = useState<{ [key: string]: UserDto }>({});
  const [attendants, setAttendants] = useState<{ [key: string]: UserDto }>({});

  const { user, userData } = useContext(FirebaseContext);

  const isJoined = useMemo(() => {
    return user !== undefined && user !== null && Object.keys(attendants).includes(user.uid);
  }, [user, attendants]);

  useEffect(() => {
    (async () => {
      if (!id) return;

      try {
        const dbRef = Database.ref(db, `events/${id}`);
        const snapshot = await Database.get(dbRef);
        setData(snapshot.val());
      } catch (error) {
        alert(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (!data) return;

    (async () => {
      if (!data.hosts) return;
      var hosts: { [key: string]: UserDto } = {};
      await Promise.all(data.hosts.map((e) => getUserByUid(e).then((user) => (hosts[e] = user))));

      setHosts(hosts);
    })();

    (async () => {
      if (!data.attendants) return;
      var attendants: { [key: string]: UserDto } = {};
      await Promise.all(Object.values(data.attendants).map((e) => getUserByUid(e).then((user) => (attendants[e] = user))));

      setAttendants(attendants);
    })();
  }, [data]);

  async function onJoin() {
    if (!user) return;
    if (!data) return;
    if (!id) return;

    try {
      if (!data.price || data.price === 0) {
        const dbRef = Database.ref(db, `events/${id}/attendants`);
        await Database.push(dbRef, user.uid);
        alert('참여 성공!');
        window.location.reload();
      } else {
        await purchaseEvent(user, id, () => {
          alert('참여 성공!');
          window.location.reload();
        });
      }
    } catch (error) {
      alert(error);
      return;
    }
  }

  return (
    <>
      <Header />

      <Styled.EventDetailContainer>
        <Styled.EventDetailBackground />

        <Common.SizedBoxH height={16} />

        <Styled.EventDetailThumbnailContainer>
          <Styled.EventDetailThumbnailTitle>
            <Typo.Yangjin fontSize={42} color={Colors.neutralWhite} textAlign="left">
              {data?.title}
            </Typo.Yangjin>
          </Styled.EventDetailThumbnailTitle>
          <Styled.EventDetailThumbnailImage src={data?.thumbnail} />
        </Styled.EventDetailThumbnailContainer>

        <Styled.EventDetailInformation>
          <Common.FlexRow>
            <Typo.IBM_SemiBold color="#393939" fontSize={16}>
              일시
            </Typo.IBM_SemiBold>
            <Common.SizedBoxW width={24} />
            <Typo.IBM_Regular fontSize={16} color="#393939">
              {data?.date && dayjs(data.date).locale('ko').format('YY.MM.DD (ddd) h:mm A')}
            </Typo.IBM_Regular>
          </Common.FlexRow>

          <Common.SizedBoxH height={16} />

          <Common.FlexRow>
            <Typo.IBM_SemiBold color="#393939" fontSize={16}>
              장소
            </Typo.IBM_SemiBold>
            <Common.SizedBoxW width={24} />
            <Typo.IBM_Regular fontSize={16} color="#393939">
              {data?.location}
            </Typo.IBM_Regular>
          </Common.FlexRow>

          <Common.SizedBoxH height={16} />

          <Common.FlexRow>
            <Typo.IBM_SemiBold color="#393939" fontSize={16}>
              비용
            </Typo.IBM_SemiBold>
            <Common.SizedBoxW width={24} />
            <Typo.IBM_Regular fontSize={16} color="#393939">
              {data?.price?.toLocaleString()}원
            </Typo.IBM_Regular>
          </Common.FlexRow>

          <Common.SizedBoxH height={16} />

          <Common.FlexRow alignItems="flex-end">
            <Typo.IBM_SemiBold color="#393939" fontSize={16}>
              인원
            </Typo.IBM_SemiBold>
            <Common.SizedBoxW width={24} />
            <Typo.IBM_Regular fontSize={16} color="#393939">
              {Object.keys(attendants).length}&nbsp;
            </Typo.IBM_Regular>
            <Typo.IBM_Regular fontSize={14} color="#393939">
              /&nbsp;{data?.limit}
            </Typo.IBM_Regular>
          </Common.FlexRow>

          <Common.SizedBoxH height={24} />

          <Styled.EventDetailJoinButton
            onClick={() => onJoin()}
            disabled={isJoined || Object.keys(attendants).length >= (data?.limit ?? 0)}
          >
            {isJoined ? <>참여 신청 완료</> : Object.keys(attendants).length >= (data?.limit ?? 0) ? <>참여 신청 마감</> : <>참여하기</>}
          </Styled.EventDetailJoinButton>
          {isJoined && (
            <>
              <Common.SizedBoxH height={24} />
              <Typo.IBM_Regular fontSize={14} color="#F25C54">
                사정이 생겨 참여가 어려우신가요?
                <br />
                오른쪽 아래에 있는 깨비에게 도움을 요청해 주세요!
              </Typo.IBM_Regular>
            </>
          )}
        </Styled.EventDetailInformation>

        <Common.SizedBoxH height={64} />

        <Common.FlexRow width={1088} alignItems="flex-start">
          <Common.SizedBoxW width={27} />

          <Common.FlexColumn width={638} alignItems="flex-start">
            <Typo.Yangjin fontSize={18} color={Colors.neutralBlack}>
              About
            </Typo.Yangjin>
            <Common.SizedBoxH height={48} />
            <Typo.IBM_Regular fontSize={16} color={Colors.neutralBlack} textAlign="left">
              {data?.content}
            </Typo.IBM_Regular>
            <Common.SizedBoxH height={48} />

            <Common.SizedBoxH height={96} />

            <Typo.Yangjin fontSize={18} color={Colors.neutralBlack}>
              Hosted by
            </Typo.Yangjin>

            <Common.SizedBoxH height={48} />

            {Object.keys(hosts).map((e) => (
              <Link to={`/namecard/${e}`}>
                <Common.FlexRow alignItems="center">
                  <Common.Circle width={65} height={65}>
                    <Common.FlexRow alignItems="center" justifyContent="center">
                      <Common.SizedImage src={hosts[e].profile ?? CommonImages.ProfileDefault} width={65} height={65} objectFit="cover" />
                    </Common.FlexRow>
                  </Common.Circle>

                  <Common.SizedBoxW width={12} />
                  <Common.FlexColumn alignItems="flex-start" justifyContent="center">
                    <Typo.IBM_SemiBold fontSize={18} color={Colors.neutralBlack} textAlign="left">
                      {hosts[e].username}
                    </Typo.IBM_SemiBold>
                    <Typo.IBM_SemiBold fontSize={14} color="#8E8E8E" textAlign="left">
                      {hosts[e].job}
                    </Typo.IBM_SemiBold>
                  </Common.FlexColumn>
                </Common.FlexRow>

                <Common.SizedBoxH height={48} />

                <Typo.IBM_Regular fontSize={16} color={Colors.neutralBlack} textAlign="left">
                  {hosts[e].introduce}
                </Typo.IBM_Regular>

                <Common.SizedBoxH height={48} />
              </Link>
            ))}

            <Common.SizedBoxH height={96} />

            <Typo.Yangjin fontSize={18} color={Colors.neutralBlack}>
              Guests
            </Typo.Yangjin>

            <Common.SizedBoxH height={48} />

            {Object.keys(attendants).length > 0 ? (
              <>
                <Common.FlexRow>
                  <Typo.IBM_SemiBold fontSize={18} color={'#F25C54'} textAlign="left">
                    {Object.keys(attendants).length}
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_Regular fontSize={18} color={Colors.neutralBlack}>
                    명의 게스트가 이벤트에 참여해요.
                  </Typo.IBM_Regular>
                </Common.FlexRow>
                <Common.SizedBoxH height={48} />
                <Styled.EventDetailAttendants>
                  {Object.keys(attendants).map((e) => (
                    <Link to={`/namecard/${e}`}>
                      <Common.Circle width={36} height={36}>
                        <Common.FlexRow alignItems="center" justifyContent="center">
                          <Common.SizedImage src={attendants[e].profile ?? CommonImages.ProfileDefault} width={36} height={36} />
                        </Common.FlexRow>
                      </Common.Circle>
                    </Link>
                  ))}
                </Styled.EventDetailAttendants>
              </>
            ) : (
              <Typo.IBM_Regular fontSize={18} color={Colors.neutralBlack}>
                아직 게스트가 없어요...
              </Typo.IBM_Regular>
            )}
            <Common.SizedBoxH height={48} />
          </Common.FlexColumn>
        </Common.FlexRow>
      </Styled.EventDetailContainer>

      <Footer />
    </>
  );
}

export default EventDetail;
