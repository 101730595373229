import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { applyActionCode, confirmPasswordReset, sendPasswordResetEmail, verifyPasswordResetCode } from 'firebase/auth';
import React, { ReactElement, useEffect, useState } from 'react';
import { auth } from 'services/firebase';
import * as Styled from './callback.style';

import { Colors } from 'common';
import { Icons } from 'common/images';
import { toast } from 'react-toastify';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';

function Callback(): ReactElement {
  const urlParams = new URLSearchParams(location.search);
  const mode = urlParams.get('mode');
  const code = urlParams.get('oobCode');

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordDisabled, setPasswordDisabled] = useState(false);

  const [verifyResult, setVerifyResult] = useState('pending');

  const navigate = useNavigate();

  useEffect(() => {
    if (!mode || !code) {
      alert('잘못된 요청입니다!');
      navigate('/');
      return;
    }

    if (mode === 'verifyEmail') {
      (async () => {
        try {
          await applyActionCode(auth, code);
          setVerifyResult('success');
        } catch (error) {
          setVerifyResult('failed');
        }
      })();
    }
  }, []);

  async function onResetPassword() {
    if (!mode || !code) {
      alert('잘못된 요청입니다!');
      navigate('/');
      return;
    }

    const passwordValue = (password ?? '').trim();
    const passwordConfirmValue = (passwordConfirm ?? '').trim();

    if (!passwordValue) {
      alert('비밀번호를 입력해주세요.');
      return;
    }

    if (!passwordConfirmValue) {
      alert('비밀번호 확인을 입력해주세요.');
      return;
    }

    if (passwordValue !== passwordConfirmValue) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }

    setPasswordDisabled(true);

    try {
      const email = await verifyPasswordResetCode(auth, code);

      await confirmPasswordReset(auth, code, password);

      alert('비밀번호가 재설정 되었습니다!');

      navigate('/signin');
    } catch (error) {
      console.log(error);
      alert('비밀번호 재설정에 실패했습니다...');
    } finally {
      setPasswordDisabled(false);
    }
  }

  return (
    <Styled.CallbackContainer>
      {mode === 'resetPassword' && (
        <>
          <Typo.Yangjin fontSize={54} color="#F25C54">
            New password
          </Typo.Yangjin>

          <Common.SizedBoxH height={70} />

          <Typo.IBM_Regular fontSize={18} color={Colors.neutralBlack}>
            새 비밀번호를 입력해주세요!
          </Typo.IBM_Regular>

          <Common.SizedBoxH height={48} />

          <Styled.CallbackInput placeholder="비밀번호" value={password} onChange={(e) => setPassword(e.target.value)} type="password" />

          <Common.SizedBoxH height={16} />

          <Styled.CallbackInput
            placeholder="비밀번호 확인"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            type="password"
          />

          <Common.SizedBoxH height={24} />

          <Styled.CallbackConfirmButton disabled={passwordDisabled} onClick={onResetPassword}>
            <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite}>
              설정 완료
            </Typo.IBM_SemiBold>
          </Styled.CallbackConfirmButton>
        </>
      )}

      {mode === 'verifyEmail' && (
        <>
          <Common.SizedImage src={Icons.EmailSend} width={74} height={74} />

          <Common.SizedBoxH height={48} />

          {verifyResult === 'pending' && <></>}
          {verifyResult === 'failed' && (
            <>
              <Typo.IBM_Regular fontSize={18} color="#1b1b1b">
                앗, 이메일 인증에 실패했어요.
                <br />
                인증 코드가 유효하지 않은 것 같아요.
              </Typo.IBM_Regular>

              <Common.SizedBoxH height={48} />

              <Styled.CallbackRetryButton
                onClick={() => {
                  navigate('/email-verify');
                }}
              >
                재시도
              </Styled.CallbackRetryButton>
            </>
          )}
          {verifyResult === 'success' && (
            <>
              <Typo.IBM_Regular fontSize={18} color="#1b1b1b">
                이메일 인증이 완료되었어요!
                <br />
                이제 멤버십을 선택하러 가볼까요?
              </Typo.IBM_Regular>

              <Common.SizedBoxH height={48} />

              <Styled.CallbackRetryButton
                onClick={() => {
                  navigate('/select-membership');
                }}
              >
                네, 좋아요!
              </Styled.CallbackRetryButton>
            </>
          )}
        </>
      )}
    </Styled.CallbackContainer>
  );
}

export default Callback;
