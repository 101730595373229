import axios from 'axios';
import * as Auth from 'firebase/auth';
import * as Database from 'firebase/database';
import { auth, db } from 'services/firebase';

// const ClientKey = 'test_2F776B0A1F94C2FB4596A53498250DBC'; // test
const ClientKey = '0252FE243051786F9AE6CC421EC4BAA5'; // live

export async function purchaseMembership(user: Auth.User, callback: (e: any) => void) {
  try {
    const dbRef = Database.ref(db, 'settings/membership');
    const snapshot = await Database.get(dbRef);
    const data = snapshot.val();

    const paymentRequest: PaymentRequestDto = {
      PCD_PAY_TYPE: 'card',
      PCD_PAY_WORK: 'PAY',
      PCD_CARD_VER: '01',

      PCD_PAYER_NAME: user.uid,

      PCD_PAY_GOODS: data.name,
      PCD_PAY_TOTAL: data.price,
      clientKey: ClientKey,
      callbackFunction: callback,

      PCD_USER_DEFINE1: 'membership',
      PCD_USER_DEFINE2: 'premiumm',
    };

    (window as any).PaypleCpayAuthCheck(paymentRequest);
  } catch (error) {
    throw error;
  }
}

export async function purchaseEvent(user: Auth.User, id: string, callback: (e: any) => void) {
  try {
    const dbRef = Database.ref(db, `events/${id}`);
    const snapshot = await Database.get(dbRef);
    const data = snapshot.val();

    const paymentRequest: PaymentRequestDto = {
      PCD_PAY_TYPE: 'card',
      PCD_PAY_WORK: 'PAY',
      PCD_CARD_VER: '01',
      PCD_PAYER_NAME: user.uid,

      PCD_PAY_GOODS: data.title,
      PCD_PAY_TOTAL: data.price,
      clientKey: ClientKey,
      callbackFunction: callback,

      PCD_USER_DEFINE1: 'event',
      PCD_USER_DEFINE2: id,
    };

    (window as any).PaypleCpayAuthCheck(paymentRequest);
  } catch (error) {
    throw error;
  }
}
