import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { DiscordRedirectUrl } from 'common/urls';
import { getDiscordAvaterImageUrl } from 'common/utils';
import { getIdToken } from 'firebase/auth';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Styled from './discordAccount.style';
import { FirebaseContext } from 'context/firebaseContext';
import { setDiscordAccount } from 'api/firebase-api';

function DiscordAccount(): ReactElement {
  let navigate = useNavigate();
  let location = useLocation();
  const { user } = useContext(FirebaseContext);

  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get('code');

  const [discordUserData, setDiscordUserData] = useState<DiscordUserDto | null>(null);

  useEffect(() => {
    if (user === undefined) return;

    if (user && code) {
      (async () => {
        const response = await setDiscordAccount(user, {
          code: code,
          redirectUri: DiscordRedirectUrl,
        });
        setDiscordUserData(response);
      })();
    }
  }, [user]);
  return (
    <>
      {!user || !code || !discordUserData ? (
        <></>
      ) : (
        <Styled.Container>
          <Common.FlexRow>
            <Common.Circle width={100} height={100}>
              <Common.SizedImage width={100} height={100} src={getDiscordAvaterImageUrl(discordUserData)} />
            </Common.Circle>
            <Typo.IBM_Regular>{discordUserData.id}</Typo.IBM_Regular>
          </Common.FlexRow>
          <Common.RoundedFilledButton
            width={200}
            height={80}
            onClick={() => {
              window.close();
            }}
          >
            닫기
          </Common.RoundedFilledButton>
        </Styled.Container>
      )}
    </>
  );
}

export default DiscordAccount;
