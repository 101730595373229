import React, { ReactElement, useContext, useState, useEffect } from 'react';
import { FirebaseContext } from 'context/firebaseContext';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from 'services/firebase';

import * as Styled from './signup.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { CommonImages, Icons, ProviderIcons } from 'common/images';
import { Colors } from 'common';
import { needEmailVerification } from 'common/utils';
import validator from 'validator';
import { signWithGoogle, signup } from 'api/firebase-api';
import { PrivacyPolicyUrl, TermsUrl } from 'common/urls';

function SignUp(): ReactElement {
  const { user, userData } = useContext(FirebaseContext);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');

  const urlParams = new URLSearchParams(location.search);
  const redirect = urlParams.get('redirect');

  const navigate = useNavigate();

  useEffect(() => {
    if (user === undefined) return;

    if (user) {
      if (redirect) {
        window.location.replace(`/${redirect}`);
        return;
      }

      if (needEmailVerification(user)) {
        window.location.replace('/email-verify');
        return;
      }

      if (userData === undefined) return;

      if (!userData) {
        window.location.replace('/select-membership');
        return;
      }

      window.location.replace('/');
      return;
    }
  }, [user, userData]);

  async function onSignUp(): Promise<void> {
    const emailValue = (email ?? '').trim();
    const passwordValue = (password ?? '').trim();
    const passwordConfirmValue = (passwordConfirm ?? '').trim();

    if (!emailValue) {
      alert('이메일을 입력해주세요.');
      return;
    }

    if (!passwordValue) {
      alert('비밀번호를 입력해주세요.');
      return;
    }

    if (!passwordConfirmValue) {
      alert('비밀번호 확인을 입력해주세요.');
      return;
    }

    if (!validator.isEmail(emailValue)) {
      alert('이메일 형식이 올바르지 않습니다.');
      return;
    }

    if (passwordValue !== passwordConfirmValue) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }

    try {
      const user = await signup(emailValue, passwordValue);
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        if (confirm('이미 가입된 이메일입니다! 로그인 화면으로 이동할까요?')) navigate('/signin');
      } else {
        alert(error);
      }
      return;
    } finally {
      setEmail('');
      setPassword('');
      setPasswordConfirm('');
    }
  }

  return (
    <Styled.SignUpContainer>
      <Styled.BackButton onClick={() => navigate(-1)}>
        <Common.SizedImage src={Icons.Back} width={20} height={21} />
      </Styled.BackButton>

      <Common.FlexRow alignItems="center" justifyContent="center">
        <Link to="/">
          <Common.SizedImage src={CommonImages.Logo} width={58.4} height={64} />
        </Link>
        <Common.SizedBoxW width={16} />
        <Typo.Yangjin fontSize={54} color="#F25C54">
          Let's begin
        </Typo.Yangjin>
      </Common.FlexRow>

      <Common.SizedBoxH height={80} />

      <Styled.SignUpInput value={email} onChange={(e) => setEmail(e.target.value)} placeholder="이메일" type="email" />

      <Common.SizedBoxH height={12} />

      <Styled.SignUpInput value={password} onChange={(e) => setPassword(e.target.value)} placeholder="비밀번호" type="password" />

      <Common.SizedBoxH height={12} />

      <Styled.SignUpInput
        value={passwordConfirm}
        onChange={(e) => setPasswordConfirm(e.target.value)}
        placeholder="비밀번호 확인"
        type="password"
      />

      <Common.SizedBoxH height={24} />

      <Styled.SignUpConfirmButton onClick={onSignUp}>시작하기</Styled.SignUpConfirmButton>

      <Common.SizedBoxH height={36} />

      <Common.FlexRow width={320} alignItems="center" justifyContent="space-between">
        <Common.Dash width={123.5} height={0.4} color="#393939" />
        <Typo.IBM_Regular fontSize={14} color="#393939">
          또는
        </Typo.IBM_Regular>
        <Common.Dash width={123.5} height={0.4} color="#393939" />
      </Common.FlexRow>

      <Common.SizedBoxH height={36} />

      <Styled.SignUpGoogleButton onClick={signWithGoogle}>
        <Common.SizedImage src={ProviderIcons.Google} width={18} height={18} />
        <Common.SizedBoxW width={8} />
        <Typo.IBM_SemiBold fontSize={16} color="#393939">
          구글 계정으로 시작하기
        </Typo.IBM_SemiBold>
      </Styled.SignUpGoogleButton>

      <Common.SizedBoxH height={36} />

      <Styled.SignUpDescription>
        시작하기를 눌러 노마드인서울&nbsp;
        <mark onClick={() => window.open(TermsUrl)}>서비스이용약관</mark>과
      </Styled.SignUpDescription>

      <Styled.SignUpDescription>
        <mark onClick={() => window.open(PrivacyPolicyUrl)}>개인정보수집·이용에 대한 안내</mark>에 동의합니다.
      </Styled.SignUpDescription>

      <Common.SizedBoxH height={36} />

      <Common.FlexRow>
        <Typo.IBM_Regular fontSize={14} color="#F25C54">
          이미 계정이 있으신가요?&nbsp;
        </Typo.IBM_Regular>
        <Link to="/signin">
          <Typo.IBM_SemiBold fontSize={14} color="#F25C54" textDecoration="underline">
            로그인하기
          </Typo.IBM_SemiBold>
        </Link>
      </Common.FlexRow>
    </Styled.SignUpContainer>
  );
}

export default SignUp;
