import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from './emailVerify.style';

import { Colors } from 'common';
import { Icons } from 'common/images';
import { needEmailVerification } from 'common/utils';
import { FirebaseContext } from 'context/firebaseContext';
import { sendEmail } from 'api/firebase-api';
import { signOut } from 'firebase/auth';
import { auth } from 'services/firebase';

function EmailVerify(): ReactElement {
  const { user, userData } = useContext(FirebaseContext);

  const [sendResult, setSendResult] = useState('pending');
  const navigate = useNavigate();

  useEffect(() => {
    if (user === undefined) return;

    if (user === null) {
      navigate('/signin');
      return;
    }

    if (!needEmailVerification(user)) {
      navigate('/');
      return;
    }

    if (sendResult === 'pending') onSendEmail();
  }, [user, userData]);

  async function onSendEmail() {
    if (!user) return;

    try {
      setSendResult('pending');
      await sendEmail(user);
      setSendResult('success');
    } catch (error: any) {
      setSendResult('failed');
    }
  }

  return (
    <Styled.EmailVerifyContainer>
      <Common.SizedImage src={Icons.EmailSend} width={74} height={74} />

      <Common.SizedBoxH height={48} />
      {sendResult === 'pending' && (
        <Typo.IBM_Regular fontSize={18} color={Colors.neutralBlack}>
          인증 이메일 보내는 중...
        </Typo.IBM_Regular>
      )}

      {sendResult === 'success' && (
        <>
          <Typo.IBM_Regular fontSize={18} color={Colors.neutralBlack}>
            입력하신 이메일 주소로 인증 메일을 보냈어요. 메일함을 확인해주세요.
            <br />
            이메일이 오지 않을 경우, 스팸 메일함을 확인해 보세요.
          </Typo.IBM_Regular>

          <Common.SizedBoxH height={48} />

          <Common.FlexRow alignItems="center" justifyContent="center">
            <Styled.EmailVerifySignOutButton
              onClick={() => {
                auth.signOut();
              }}
            >
              다른 계정으로 로그인
            </Styled.EmailVerifySignOutButton>
            <Common.SizedBoxW width={8} />
            <Styled.EmailVerifyResendButton onClick={() => onSendEmail()}>이메일 재전송</Styled.EmailVerifyResendButton>
          </Common.FlexRow>
        </>
      )}

      {sendResult === 'failed' && (
        <>
          <Typo.IBM_Regular fontSize={18} color={Colors.neutralBlack}>
            이메일 전송에 실패했어요.. 잠시 후에 다시 시도해주세요!
          </Typo.IBM_Regular>

          <Common.SizedBoxH height={48} />

          <Common.FlexRow alignItems="center" justifyContent="center">
            <Styled.EmailVerifySignOutButton
              onClick={() => {
                auth.signOut();
              }}
            >
              다른 계정으로 로그인
            </Styled.EmailVerifySignOutButton>
            <Common.SizedBoxW width={8} />
            <Styled.EmailVerifyResendButton onClick={() => onSendEmail()}>이메일 재전송</Styled.EmailVerifyResendButton>
          </Common.FlexRow>
        </>
      )}
    </Styled.EmailVerifyContainer>
  );
}

export default EmailVerify;
