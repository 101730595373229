import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  width: 100%;
  max-width: 100%;
`;
