import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

import BackgroundImage from 'img/background/Background_0.png';

export const EventDetailContainer = styled.div`
  width: 100%;
  min-height: 764px;

  padding: 72px 0 0 0;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EventDetailBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 764px;

  background-image: linear-gradient(
      0deg,
      #fff 46.58%,
      rgba(255, 255, 255, 0.7) 69.79%,
      rgba(255, 255, 255, 0.7) 100%,
      #fff 100%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(${BackgroundImage});
  background-size: cover;
  background-position: center;

  z-index: -1;
`;

export const EventDetailThumbnailContainer = styled.div`
  width: 1088px;
  height: 444px;

  border-radius: 36px;

  overflow: hidden;
  position: relative;
`;

export const EventDetailThumbnailImage = styled.img`
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 1;
`;

export const EventDetailThumbnailTitle = styled.div`
  background: radial-gradient(51.14% 72.1% at 5.68% 88.91%, rgba(57, 57, 57, 0.8) 0%, rgba(57, 57, 57, 0) 100%);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  padding: 55px 42px;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 2;
`;

export const EventDetailInformation = styled.div`
  width: 360px;
  padding: 36px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-radius: 24px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 12px 20px 0px rgba(90, 90, 90, 0.1);

  backdrop-filter: blur(10px);

  position: absolute;
  top: 394px;
  left: calc(50% + 167px);

  z-index: 10;
`;

export const EventDetailJoinButton = styled.button`
  padding: 12px 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  align-self: stretch;

  border-radius: 90px;
  background: var(--P5, #f25c54);
  border: none;

  color: var(--White, #fff);
  text-align: center;

  font-family: IBMPlexSansKR;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  :disabled {
    background: var(--G3, #8e8e8e);
  }
`;

export const EventDetailAttendants = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px;
`;
