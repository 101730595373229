import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

import BackgroundImage from 'img/background/Background_0.png';

export const SendResetPasswordContainer = styled.div`
  width: 100%;
  min-height: 764px;
  height: 100vh;

  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.63) 0%,
      rgba(255, 255, 255, 0.63) 32.81%,
      rgba(255, 255, 255, 0.56) 67.71%,
      rgba(255, 255, 255, 0.49) 100%
    ),
    url(${BackgroundImage});
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
`;

export const SendResetPasswordInput = styled.input`
  width: 300px;
  height: 45px;

  padding: 12px 8px;

  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(117deg, rgba(255, 255, 255, 0) -3.92%, rgba(255, 255, 255, 0.12) 75.27%);
  backdrop-filter: blur(5px);

  color: var(--G3, #393939);

  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  ::placeholder {
    color: var(--G3, #8e8e8e);
  }
`;

export const SendResetPasswordButton = styled.button`
  border-radius: 90px;
  border: none;
  background: var(--P5, #f25c54);

  width: 300px;
  height: 45px;

  padding: 12px 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--White, #fff);
  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;

  :disabled {
    opacity: 0.5;
  }
`;

export const BackButton = styled.button`
  position: absolute;
  top: 24px;
  left: 24px;
  width: 20px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0;
  border: none;
`;
