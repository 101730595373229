import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

import BackgroundImage from 'img/background/Background_0.png';

export const CallbackContainer = styled.div`
  width: 100%;
  min-height: 764px;
  height: 100vh;

  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.63) 0%,
      rgba(255, 255, 255, 0.63) 32.81%,
      rgba(255, 255, 255, 0.56) 67.71%,
      rgba(255, 255, 255, 0.49) 100%
    ),
    url(${BackgroundImage});
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CallbackInput = styled.input`
  width: 300px;
  height: 45px;

  padding: 12px 8px;

  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(117deg, rgba(255, 255, 255, 0) -3.92%, rgba(255, 255, 255, 0.12) 75.27%);
  backdrop-filter: blur(5px);

  color: var(--G3, #393939);

  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  ::placeholder {
    color: var(--G3, #8e8e8e);
  }
`;

export const CallbackConfirmButton = styled.button`
  border-radius: 90px;
  background: var(--P5, #f25c54);

  box-shadow: 0px 15px 30px 0px rgba(90, 90, 90, 0.1);

  border: none;

  width: 300px;
  height: 45px;

  padding: 12px 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--White, #fff);
  text-align: center;
  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CallbackCancelButton = styled.button`
  border-radius: 90px;
  background: var(--G1, #f8f8f8);

  box-shadow: 0px 15px 30px 0px rgba(90, 90, 90, 0.1);

  border: none;

  height: 45px;

  padding: 12px 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--G3, #8e8e8e);
  text-align: center;
  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CallbackRetryButton = styled.button`
  border-radius: 90px;
  background: var(--P5, #f25c54);

  box-shadow: 0px 15px 30px 0px rgba(90, 90, 90, 0.1);

  border: none;

  height: 45px;

  padding: 12px 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--White, #fff);
  text-align: center;
  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
