import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { sendEmailVerification } from 'firebase/auth';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Styled from './agits.style';

import { Colors } from 'common';
import { Icons } from 'common/images';
import { needEmailVerification } from 'common/utils';
import { FirebaseContext } from 'context/firebaseContext';

import * as Database from 'firebase/database';
import { db } from 'services/firebase';
import Header from 'components/header';

import MapImage from 'img/agits/map.png';
import AgitFindIcon from 'img/agits/AgitIcon.svg';

function Agits(): ReactElement {
  return (
    <>
      <Header />

      <Styled.AgitsContainer>
        <Typo.Nanum_Hipi fontSize={24} color="#393939">
          내 주변에 일하기 좋은 공간 어디 없을까?
        </Typo.Nanum_Hipi>

        <Common.SizedBoxH height={36} />

        <Styled.AgitsButton
          onClick={() => {
            window.open('https://www.oneroof.co.kr/');
          }}
        >
          <Common.SizedImage src={AgitFindIcon} width={9.05} height={11} />
          <Common.SizedBoxW width={4} />
          <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite}>
            아지트 찾기
          </Typo.IBM_SemiBold>
        </Styled.AgitsButton>

        <Common.SizedBoxH height={64} />

        <Styled.AgitsImageContainer>
          <Common.SizedImage src={MapImage} width={710} height={305} objectFit="cover" />
        </Styled.AgitsImageContainer>

        <Common.SizedBoxH height={36} />

        <Common.FlexRow>
          <Typo.IBM_SemiBold fontSize={16} color={Colors.neutralBlack}>
            원루프
          </Typo.IBM_SemiBold>
          <Typo.IBM_Regular fontSize={16} color={Colors.neutralBlack}>
            에서 전국 곳곳의 코워킹 스페이스를 탐색해 보세요!
          </Typo.IBM_Regular>
        </Common.FlexRow>
      </Styled.AgitsContainer>
    </>
  );
}

export default Agits;
