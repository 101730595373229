import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import * as Styled from 'App.style';
import * as Components from 'components';
import { FirebaseContext, FirebaseAuthProvider } from 'context/firebaseContext';
import { createGlobalStyle } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

// fonts
import Yangjin from 'fonts/YANGJIN/YANGJINv0.9_ttf.ttf';
import IBMRegular from 'fonts/IBM_Plex_Sans_KR/IBMPlexSansKR-Regular.ttf';
import IBMSemiBold from 'fonts/IBM_Plex_Sans_KR/IBMPlexSansKR-SemiBold.ttf';
import IBMBold from 'fonts/IBM_Plex_Sans_KR/IBMPlexSansKR-Bold.ttf';
import NanumBaReunHiPi from 'fonts/NanumBaReunHiPi/NanumBaReunHiPi.ttf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: IBMPlexSansKR;
    src:url(${IBMRegular}) format('truetype'); 
    font-weight: normal;
  }

  @font-face {
    font-family: IBMPlexSansKR;
    src:url(${IBMSemiBold}) format('truetype'); 
    font-weight: 600;
  }

  @font-face {
    font-family: IBMPlexSansKR;
    src:url(${IBMBold}) format('truetype'); 
    font-weight: 700;
  }

  @font-face {
    font-family: Yangjin;
    src:url(${Yangjin}) format('truetype'); 
  }

  @font-face {
    font-family: Nanum BaReunHiPi;
    src: url(${NanumBaReunHiPi}) format('truetype');
  }

  body {
    font-family: IBMPlexSansKR, Yangjin, Nanum BaReunHiPi;
  }
`;

export default function App(): ReactElement {
  return (
    <Styled.Container>
      <GlobalStyle />
      <ToastContainer />
      <FirebaseAuthProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<Components.Main />} path="/" />
            <Route element={<Components.Terms />} path="terms" />
            <Route element={<Components.PrivacyPolicy />} path="privacy-policy" />

            <Route element={<Components.Callback />} path="callback" />

            <Route element={<Components.SignUp />} path="signup" />
            <Route element={<Components.SignIn />} path="signin" />
            <Route element={<Components.EmailVerify />} path="email-verify" />
            <Route element={<Components.SendResetPassword />} path="send-reset-password" />

            <Route element={<Components.SelectMembership />} path="select-membership" />

            <Route path="namecard">
              <Route element={<Components.Namecard />} path=":id" />
            </Route>
            <Route element={<Components.DiscordAccount />} path="discord-account" />

            <Route element={<Components.Events />} path="events" />
            <Route path="event-detail">
              <Route element={<Components.EventDetail />} path=":id" />
            </Route>

            <Route element={<Components.Agits />} path="agits" />
          </Routes>
        </BrowserRouter>
      </FirebaseAuthProvider>
    </Styled.Container>
  );
}
