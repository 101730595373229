import { Colors } from 'common';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import React, { ReactElement, useContext } from 'react';
import * as Styled from './privacyPolicy.style';

function PrivacyPolicy(): ReactElement {
  return (
    <>
      <Header />

      <Styled.Container>
        <Typo.IBM_SemiBold fontSize={32} color={Colors.neutralBlack} textAlign="center">
          노마드인서울 개인정보 처리방침
        </Typo.IBM_SemiBold>

        <Styled.Content color={Colors.neutralBlack} textAlign="left">
          공고일자 : 2023년 2월 21일
          <br />
          시행일자 : 2023년 2월 21일
          <br />
          <br />
          <h1>노마드인서울 개인정보 처리방침</h1>
          <br />
          노마드인서울(이하 ‘회사’)는 이용자의 동의를 기반으로 개인정보를 수집·이용 및 제공하고 있으며, 이용자의 권리(개인정보 자기결정권)를
          적극적으로 보장합니다. 회사는 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정, 가이드라인을
          준수하고 있습니다.
          <br />
          <br />
          ‘개인정보 처리방침’이란 이용자의 소중한 개인정보를 보호하여 안심하고 서비스를 이용할 수 있도록 회사가 서비스를 운영함에 있어
          준수해야 할 지침을 의미합니다.
          <br />
          <br />
          <h1>제1조 (개인정보 수집)</h1>
          <br />
          이용자는 회원가입을 하지 않아도 일부 서비스를 회원과 동일하게 이용할 수 있고, 회원가입을 통해 더욱 다양한 서비스를 제공받을 수
          있습니다. 이용자가 회원가입을 할 경우, 회사는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.
          <br />
          <br />
          회원가입 시점에 회사는 이용자로부터 아래와 같은 개인정보를 수집합니다.
          <ol>
            <li>일반 회원</li>
            <ul>
              <li>필수 정보: 이름, 성별, 생년월일, 휴대전화번호, 이메일주소, 비밀번호</li>
              <li>선택 정보: 페이스북 계정, 가입경로, 관심사</li>
            </ul>
            필수 정보란 서비스의 본질적 기능을 수행하기 위해 필요한 정보이며, 선택 정보란 서비스 이용 자체에 영향을 미치지는 않으나
            이용자에게 부가적인 가치를 제공하기 위해 추가로 수집하는 정보입니다. 회원가입 시에 수집하는 이메일주소, 비밀번호는 로그인을
            위하여 필요한 정보이며 이름, 생년월일, 성별, 휴대전화번호는 회원제 서비스 운영에 따르는 이용자 구분을 위하여 사용됩니다.
            <li>유료 서비스 이용 회원</li>
            유료 서비스 이용(결제) 과정에서 아래와 같은 정보가 개인정보 수탁자(결제대행사)에 의해 수집될 수 있습니다.
            <ul>
              <li>카드결제 이용 시: 이름, 카드사명, 카드번호 등 결제에 필요한 정보</li>
              <li>계좌이체(가상계좌) 이용 시: 이름, 은행명, 계좌번호 등 결제에 필요한 정보</li>
            </ul>
          </ol>
          이 외에 웹 서비스 이용과정에서 아래와 같은 정보가 자동으로 생성되어 수집될 수 있습니다.
          <ul>
            <li>- 단말기정보, 접속IP주소, 쿠키, 방문일시, 지불정보 및 기록, 서비스 이용 기록, 불량 이용 기록</li>
          </ul>
          서비스 이용과정에서 이용자로부터 수집하는 개인정보는 서비스에 따라 차이가 있을 수 있습니다.
          <br />
          추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서 이용자에게 ‘수집하는 개인정보 항목, 개인정보의 수집 및 이용목적,
          개인정보의 보관기간’에 대해 안내하고 동의를 받습니다.
          <br />
          <br />
          회사는 아래의 방법을 통해 개인정보를 수집합니다
          <ol>
            <li>
              회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를
              수집합니다.
            </li>
            <li>
              고객센터 및 담당직원을 통한 상담 과정에서 웹페이지, 메일, 팩스, 문자, 전화 등을 통해 이용자의 개인정보가 수집될 수 있습니다.
            </li>
            <li>오프라인에서 진행되는 이벤트, 세미나 등에서 서면을 통해 개인정보가 수집될 수 있습니다.</li>
            <li>
              회사와 제휴한 외부 기업이나 단체로부터 개인정보를 제공받을 수 있으며, 이러한 경우에는 정보통신망법에 따라 제휴사에서
              이용자에게 개인정보 제공 동의 등을 받은 후에 노마드인서울에 제공합니다.
            </li>
            <li>기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다.</li>
          </ol>
          <br />
          <br />
          <h1>제 2조 (개인정보 처리 및 보유)</h1>
          <br />
          회사는 이용자의 개인정보를 다음과 같은 목적으로만 처리합니다.
          <ol>
            <li>회원관리</li>
            <ul>
              <li>이용자 식별, 가입의사 확인, 본인 확인, 회원탈퇴 의사 확인, 불량회원 부정 이용 방지 등</li>
              <li>
                법령 및 회사 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는
                행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 공지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등
              </li>
            </ul>
            <li>2. 서비스 개발, 제공 및 향상</li>
            <ul>
              <li>
                콘텐츠 등 서비스 제공(광고 포함), 서비스 관련 알림 및 안내, 인구통계학적 분석, 서비스 방문 및 이용기록의 분석, 개인정보 및
                관심사에 기반한 이용자간 관계의 형성, 지인 및 관심사 등에 기반한 맞춤형 서비스 제공 등 신규 서비스 요소의 발굴 및 기존
                서비스 개선
              </li>
              <li>유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제, 상품 및 서비스의 배송</li>
              <li>이벤트 정보 및 참여기회 제공, 광고성 정보 제공</li>
              <li>
                서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등
              </li>
            </ul>
            <li>안전한 이용환경 구축</li>
            <ul>
              <li>보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축</li>
            </ul>
          </ol>
          회사는 이용자로부터 동의 받은 처리목적이 달성되거나 법령에 따른 보존기간이 달성될 때까지 개인정보를 처리 및 보유합니다.
          <br />
          <br />
          <h1>제 3조 (개인정보 제공 및 위탁)</h1>
          회사는 원칙적으로 이용자 동의 없이 이용자의 개인정보를 외부에 공개하지 않습니다. 단 이용자가 외부 제휴사의 서비스를 이용하기
          위하여 개인정보 제공 및 위탁에 직접 동의한 경우, 관련 법령 규정에 의거하거나 수사 목적으로 법령에 정해진 절차와 방법에 따라
          수사기관의 요구가 있는 경우, 이용자의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 경우는 예외로 합니다. 또한
          통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우에는 특정 개인을 알아볼 수 없는 통계 자료의 형태로 가공하여 제공할 수
          있습니다.
          <br />
          <br />
          회사는 원활한 서비스 제공을 위해 아래 수탁자에게 최소한의 개인정보를 위탁하고 있습니다.
          <ul>
            <li>(주)페이플: 신용카드 결제 처리 및 구매안전서비스 등 서비스 제공을 위한 카드사명, 카드번호, 유효기간, 생년월일 등</li>
            <li>MailChimp: 알림 메일 발송을 위한 성명, 메일주소 등</li>
          </ul>
          <br />
          <h1>제 4조 (개인정보 파기)</h1>
          회사는 이용자의 개인정보에 대해 처리목적이 달성되어 불필요하게 되었을 때는 해당 개인정보를 지체 없이 파기합니다.
          <br />
          전자적 파일 형태인 경우 복구 및 재생되지 않도록 기술적인 방법을 이용하여 완전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의 경우
          분쇄하거나 소각하여 파기합니다.
          <br />
          <br />
          단, 관계법령에 따라 개인정보 보존의무가 부과되는 경우에는 법령이 정한 기간 동안 보존합니다. 관계법령에 의해 보존해야 하는 정보와
          보존기간은 아래와 같습니다.
          <ul>
            <li>표시/광고에 관한 기록: 6개월</li>
            <li>계약 또는 청약철회에 관한 기록: 5년</li>
            <li>대금결제 및 재화 등의 공급에 관한 기록: 5년</li>
            <li>소비자의 불만 또는 분쟁처리에 관한 기록: 3년</li>
            <li>서비스 이용 관련 개인정보(로그인 기록): 3개월</li>
            <li>전자금융에 관한 기록: 5년</li>
            <li>신용정보의 수집, 처리 및 이용 등에 관한 기록: 3년</li>
          </ul>
          <br />
          <h1>제 5조 (이용자의 권리와 행사 방법)</h1>
          <br />
          회원 및 법정대리인은 언제든지 수집 정보에 대하여 수정, 동의 철회, 삭제, 열람을 요청할 수 있습니다. 다만, 동의 철회, 삭제 시
          서비스의 일부 또는 전부 이용이 제한될 수 있습니다.
          <br />
          <br />
          회사가 수집한 개인정보는 아래와 같은 방법을 통해 확인할 수 있습니다.
          <br />
          [수집된 개인정보 확인]
          <ul>
            <li>노마드인서울 웹페이지에서 - 마이페이지</li>
          </ul>
          노마드인서울 웹 또는 앱을 통해 직접 확인하지 못하는 정보는 노마드인서울 고객센터(nomadinseoul@gmail.com)에 요청하여 확인할 수
          있습니다.
          <br />
          <br />
          개인정보 동의 철회 및 삭제, 처리 정지를 요청하고자 하는 경우에는 노마드인서울 고객센터(nomadinseoul@gmail.com)를 통해 요청할 수
          있습니다. 또한, 회원은 언제든 회원 탈퇴를 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다.
          <br />
          [회원 탈퇴 방법]
          <ul>
            <li>노마드인서울 웹페이지에서 - 마이페이지</li>
          </ul>
          요청하신 처리가 완료될 때까지 해당 정보를 이용하거나 타인에게 제공하지 않습니다. 또한, 합리적인 사유로 잘못된 개인정보를 제3자에게
          이미 제공∙위탁한 경우, 그 결과를 지체 없이 제3자에게 통지하여 동의 철회, 삭제, 처리 정지하도록 조치합니다.
          <br />
          <br />
          <h1>제 6조 (개인정보 자동 수집 장치의 설치, 운영 및 그 거부에 관한 사항)</h1>
          <br />
          회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 쿠키(cookie)를 사용합니다. 이용자가
          웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 디바이스에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된
          서비스를 제공하게 됩니다. 쿠키는 이용자가 웹 사이트를 방문할 때, 웹 사이트 사용을 설정한대로 접속하고 편리하게 사용할 수 있도록
          돕습니다. 또한, 이용자의 웹사이트 방문 기록, 이용 형태를 통해서 최적화된 광고 등 맞춤형 정보를 제공하기 위해 활용됩니다.
          <br />
          <br />
          쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서,
          이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을
          거부할 수 있습니다. 다만 쿠키 설치를 거부할 경우 웹사이트 사용이 불편해지며 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수
          있습니다.
          <br />
          <br />
          설정 방법의 예
          <ul>
            <li>Internet Explorer: 웹 브라우저 상단의 도구 메뉴 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 설정</li>
            <li>Chrome: 웹 브라우저 우측의 설정 메뉴 &gt; 화면 하단의 고급 설정 표시 &gt; 개인정보의 콘텐츠 설정 버튼 &gt; 쿠키</li>
          </ul>
          <br />
          <h1>제 7조 개인정보의 안전성 확보 조치</h1>
          <br />
          회사는 이용자의 개인정보 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
          <ul>
            <li>관리적 조치: 직원 교육, 취급자 최소화</li>
            <li>기술적 조치: 시스템 접근권한 관리, 보안프로그램 설치</li>
            <li>물리적 조치: 보관장소 접근통제</li>
          </ul>
          개인정보 보호책임자 및 담당부서
          <ul>
            <li>책임자 : 김현정</li>
            <li>담당부서: 운영</li>
            <li>문의: 고객센터 nomadinseoul@gmail.com</li>
          </ul>
          또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하셔서 도움을 받으실 수 있습니다.
          <ul>
            <li>개인정보침해신고센터: 국번 없이 118 (https://privacy.kisa.or.kr)</li>
            <li>개인정보 분쟁조정위원회: 02-2100-2499 (http://kopico.go.kr)</li>
            <li>대검찰청 사이버수사과: 국번 없이 1301 (http://www.spo.go.kr)</li>
            <li>경찰청 사이버안전국: 국번 없이 182 (http://cyberbureau.police.go.kr)</li>
          </ul>
          <br />
          <h1>제 8조 본 개인정보 처리방침의 적용 범위</h1>
          <br />본 개인정보 처리방침은 회사가 제공하는 제반 서비스에 적용되며, 다른 이름으로 제공되는 서비스에 대해서는 별개의 개인정보
          처리방침이 적용될 수 있습니다. 회사는 회원에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 회사가 포함하고
          있는 링크를 클릭하여 타 웹사이트의 페이지로 옮겨 갈 경우 해당 사이트의 개인정보 처리방침은 회사와 무관하므로 새로 방문한 사이트의
          정책을 검토해 보시기 바랍니다.
          <br />
          <br />
          <h1>제 9조 개인정보 처리방침의 개정 및 공지</h1>
          <br />
          회사는 법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로 본 개인정보 처리방침을 수정할 수 있습니다. 내용에 대한 추가, 삭제 및
          수정이 있을 경우 시행일 최소 7일 전부터 홈페이지 상에 공지합니다.
          <br />
          다만, 이용자 권리 또는 의무에 중대한 변경이 발생할 때에는 최소 30일전에 공지합니다.
        </Styled.Content>
      </Styled.Container>
    </>
  );
}

export default PrivacyPolicy;
