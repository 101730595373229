import GoogleIcon from 'img/providerIcons/Google.svg';
import KakaoTalk from 'img/providerIcons/KakaoTalk.svg';
import Naver from 'img/providerIcons/Naver.svg';
import DiscordIcon from 'img/providerIcons/discord-mark-blue.png';

export const ProviderIcons = {
  Google: GoogleIcon,
  Discord: DiscordIcon,
  KakaoTalk,
  Naver,
};

import CloseBlack from 'img/icons/CloseBlack.png';
import CloseWhite from 'img/icons/CloseWhite.png';
import Email from 'img/icons/Email.svg';
import MenuWhite from 'img/icons/menu_white.svg';
import MenuBlack from 'img/icons/menu_black.svg';
import ExpandDownWhite from 'img/icons/Expand_down.svg';
import ExpandDownBlack from 'img/icons/Expand_down_black.svg';
import EditGray from 'img/icons/EditGray.svg';
import PointOn from 'img/icons/point_on.svg';
import PointOff from 'img/icons/point_off.svg';
import Info from 'img/icons/info.svg';
import CheckPointOn from 'img/icons/CheckPointOn.svg';
import CheckPointOff from 'img/icons/CheckPointOff.svg';
import EmailSend from 'img/icons/EmailSend.svg';
import AddBlack from 'img/icons/Add_round.png';
import EventHost from 'img/icons/event_host.png';
import Pin from 'img/icons/Pin_fill.png';
import Edit from 'img/icons/Edit_fill.png';
import Link from 'img/icons/link_alt.png';
import Photo from 'img/icons/Camera_fill.png';
import Back from 'img/icons/back_icon.png';

export const Icons = {
  CloseBlack,
  CloseWhite,
  EmailGray: Email,
  MenuWhite,
  MenuBlack,
  ExpandDownWhite,
  ExpandDownBlack,
  EditGray,
  PointOn,
  PointOff,
  Info,
  CheckPointOff,
  CheckPointOn,
  EmailSend,
  AddBlack,
  EventHost,
  Pin,
  Edit,
  Link,
  Photo,
  Back,
};

import Logo from 'img/common/Logo.png';
import LogoCloseEyes from 'img/common/Logo_CloseEyes.png';
import ProfileDefault from 'img/common/profile_default.png';
import BackgroundDefault from 'img/common/background_default.svg';

export const CommonImages = {
  Logo,
  LogoCloseEyes,
  ProfileDefault,
  BackgroundDefault,
};

import MembershipSubscribe from 'img/membership/subscribe.png';
import MembershipDiscord from 'img/membership/discord.png';
import MembershipKakaoTalk from 'img/membership/kakaotalk.png';

export const MembershipIcons = {
  MembershipSubscribe,
  MembershipDiscord,
  MembershipKakaoTalk,
};

import PremiumMembershipBali from 'img/membership/bali.png';
import PremiumMembershipJob from 'img/membership/job.png';
import PremiumMembershipAgit from 'img/membership/agit.png';
import PremiumMembershipGift from 'img/membership/gift.png';
import PremiumMembershipEvent from 'img/membership/event.png';
import PremiumMembershipCoffee from 'img/membership/coffee.png';

export const PremiumMembershipBenefitIcons = [
  PremiumMembershipBali,
  PremiumMembershipJob,
  PremiumMembershipAgit,
  PremiumMembershipGift,
  PremiumMembershipEvent,
  PremiumMembershipCoffee,
];
