import { Colors } from 'common';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import React, { ReactElement, useContext } from 'react';
import * as Styled from './terms.style';

function Terms(): ReactElement {
  return (
    <>
      <Header />
    </>
  );
}

export default Terms;
