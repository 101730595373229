import styled from 'styled-components';

export const StyledText = styled.div<{
  textAlign?: string;
  verticalAlign?: string;
  fontSize?: number;
  fontStyle?: string;
  width?: number;
  height?: number;
  color?: string;
  backgroundColor?: string;
  textDecoration?: string;
  fontFamily?: string;
  fontWeight?: string | number;
  lineHeight?: string | number;
}>`
  font-stretch: normal;
  letter-spacing: normal;
  white-space: pre-line;

  ${(props) => props.lineHeight && typeof props.lineHeight === 'number' && `line-height: ${props.lineHeight}px`};
  ${(props) => props.lineHeight && typeof props.lineHeight === 'string' && `line-height: ${props.lineHeight}`};
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight}`};
  ${(props) => props.fontFamily && `font-family: ${props.fontFamily}`};
  ${(props) => props.fontStyle && `font-style: ${props.fontStyle}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) => props.verticalAlign && `vertical-align: ${props.verticalAlign}`};
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`};
  ${(props) => props.color && `color: ${props.color}`};
  ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor}`};
  ${(props) => props.width && `width: ${props.width}px`};
  ${(props) => props.height && `height: ${props.height}px`};
  ${(props) => props.textDecoration && `text-decoration: ${props.textDecoration}`};
`;

export const IBM_Regular = styled(StyledText)`
  font-family: IBMPlexSansKR;
  font-weight: normal;
`;

export const IBM_SemiBold = styled(StyledText)`
  font-family: IBMPlexSansKR;
  font-weight: 600;
`;

export const Yangjin = styled(StyledText)`
  font-family: 'Yangjin';
  font-weight: 500;
`;

export const Nanum_Hipi = styled(StyledText)`
  font-family: Nanum BaReunHiPi;
`;
