import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';
import { IBM_Regular } from 'common/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  white-space: pre-line;
`;

export const Viewport = styled.div<{ width: number; adjustSize: any }>`
  width: ${(p) => p.width}px;
  padding: ${(p) => p.adjustSize(90)}px ${(p) => p.adjustSize(10, 150)}px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled(IBM_Regular)`
  font-size: 16;

  h1 {
    font-size: 24;
    font-weight: bold;
  }
  ul.a {
    list-style-type: circle;
  }
  ul.b {
    list-style-type: circle;
  }
`;
