import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

export const NamecardContainer = styled.div<{ backgroundImage: any }>`
  width: 100%;
  min-height: 764px;
  height: 100vh;
  padding: 100px 0 0 0;

  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.63) 0%,
      rgba(255, 255, 255, 0.63) 32.81%,
      rgba(255, 255, 255, 0.56) 67.71%,
      rgba(255, 255, 255, 0.49) 100%
    ),
    url(${(p) => p.backgroundImage});
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
`;

export const NamecardBackgroundPhotoButton = styled.button`
  width: 32px;
  height: 32px;

  border-radius: 90px;
  background: var(--G1, #f8f8f8);

  /* E3 */
  box-shadow: 0px 8px 15px 0px rgba(90, 90, 90, 0.15);

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  padding: 0;

  position: absolute;
  top: 16px;
  right: 16px;
`;

export const NamecardBackgroundImageMenu = styled.div`
  padding: 4px 12px;
  gap: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  border: 1px solid #fff;
  background: linear-gradient(117deg, rgba(255, 255, 255, 0.4) -3.92%, rgba(255, 255, 255, 0.16) 75.27%);

  backdrop-filter: blur(5px);

  position: absolute;
  top: 38px;
  right: 38px;

  z-index: 10;
`;

export const NamecardContents = styled.div`
  width: 361px;
  padding: 80px 36px 48px 36px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-radius: 24px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 12px 20px 0px rgba(90, 90, 90, 0.1);
  backdrop-filter: blur(10px);

  position: relative;
`;

export const NamecardPhoto = styled.img`
  position: absolute;
  top: -126px;
  left: 90px;

  width: 180px;
  height: 180px;
  border-radius: 360px;
`;

export const NamecardMembershipTag = styled.div<{ backgroundColor: string }>`
  border-radius: 4px;
  padding: 2px 4px;
  background: ${(p) => p.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NamecardLine = styled.div`
  width: 299px;
  height: 1px;
  background: linear-gradient(90deg, rgba(250, 250, 250, 0) 0%, rgba(250, 250, 250, 0.8) 50.61%, rgba(250, 250, 250, 0) 100%);
`;

export const NamecardButton = styled.button`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;

  border-radius: 90px;
  background: rgba(57, 57, 57, 0.4);
  box-shadow: 0px 12px 20px 0px rgba(90, 90, 90, 0.1);
  backdrop-filter: blur(10px);

  border: none;
`;

export const NamecardPhotoButton = styled.button`
  width: 32px;
  height: 32px;

  border-radius: 90px;
  background: var(--G1, #f8f8f8);

  /* E3 */
  box-shadow: 0px 8px 15px 0px rgba(90, 90, 90, 0.15);

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  padding: 0;

  position: absolute;
  top: 9.75px;
  left: 227px;
`;

export const NamecardProfileImageMenu = styled.div`
  padding: 4px 12px;
  gap: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  border: 1px solid #fff;
  background: linear-gradient(117deg, rgba(255, 255, 255, 0.4) -3.92%, rgba(255, 255, 255, 0.16) 75.27%);

  backdrop-filter: blur(5px);

  position: absolute;
  top: 26px;
  left: 248px;

  z-index: 10;
`;

export const NamecardEditTabButton = styled.button`
  width: 70px;
  height: 29px;

  border: none;
  padding: 4px 8px;

  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  color: rgba(57, 57, 57, 0.6);
  background: none;

  :disabled {
    color: var(--G1, #f8f8f8);
    border: none;

    border-radius: 16px;
    background: rgba(57, 57, 57, 0.6);
  }
`;

export const NamecardInput = styled.input`
  width: 289px;
  height: 45px;

  display: flex;
  padding: 12px 8px;
  align-items: center;
  justify-content: flex-start;

  border-radius: 8px;
  border: 1px solid #fff;
  background: linear-gradient(117deg, rgba(255, 255, 255, 0) -3.92%, rgba(255, 255, 255, 0.12) 75.27%);

  color: var(--G4, #393939);
  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;

  ::before {
    backdrop-filter: blur(5px);
  }
`;

export const NamecardTextArea = styled.textarea`
  width: 289px;
  height: 108px;

  display: flex;
  padding: 12px 8px;
  align-items: center;
  justify-content: flex-start;

  border-radius: 8px;
  border: 1px solid #fff;
  background: linear-gradient(117deg, rgba(255, 255, 255, 0) -3.92%, rgba(255, 255, 255, 0.12) 75.27%);

  color: var(--G4, #393939);
  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;

  ::before {
    backdrop-filter: blur(5px);
  }
`;

export const NamecardEditCancelButton = styled.button`
  border-radius: 90px;
  background: rgba(255, 227, 224, 0.6);
  box-shadow: 0px 12px 20px 0px rgba(90, 90, 90, 0.1);

  padding: 12px 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;

  color: var(--G4, #393939);
  text-align: center;
  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  ::before {
    backdrop-filter: blur(10px);
  }
`;

export const NamecardEditConfirmButton = styled.button`
  border-radius: 90px;
  background: rgba(242, 92, 84, 0.6);
  box-shadow: 0px 12px 20px 0px rgba(90, 90, 90, 0.1);
  backdrop-filter: blur(10px);

  padding: 12px 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;

  ::before {
    backdrop-filter: blur(10px);
  }

  color: var(--White, #fff);
  text-align: center;
  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  :disabled {
    opacity: 0.6;
  }
`;

export const NamecardEditTags = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(57, 57, 57, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NamecardEditTagsContainer = styled.div`
  width: 526px;
  padding: 48px 36px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  border-radius: 24px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 12px 20px 0px rgba(90, 90, 90, 0.1);
  backdrop-filter: blur(10px);
`;

export const NamecardEditTagsToggleContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const NamecardEditTagsToggle = styled.button<{ isOn: boolean }>`
  display: flex;
  padding: 4px 8px;
  align-items: center;

  border-radius: 90px;
  border: 1px solid ${(p) => (p.isOn ? '#f25c54' : '#393939')};

  color: ${(p) => (p.isOn ? '#f25c54' : '#393939')};
  text-align: center;

  font-family: IBMPlexSansKR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  background: none;
  background-color: transparent;
`;

export const NamecardInputWithPrefix = styled.div`
  width: 253px;
  height: 45px;

  padding: 4px 8px;

  border-radius: 8px;
  border: 1px solid #fff;
  background: linear-gradient(117deg, rgba(255, 255, 255, 0) -3.92%, rgba(255, 255, 255, 0.12) 75.27%);

  ::before {
    backdrop-filter: blur(5px);
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  input {
    width: 100%;
    height: 100%;
    background: none;
    padding: none;
    border: none;

    color: var(--G4, #393939);
    font-family: IBMPlexSansKR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;

    flex-grow: 1;
  }
`;
