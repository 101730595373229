import React, { ReactElement, useContext, useState } from 'react';
import { FirebaseContext } from 'context/firebaseContext';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from 'services/firebase';

import * as Styled from './header.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { CommonImages, Icons } from 'common/images';
import { Colors } from 'common';
import OutsideClickHandler from 'react-outside-click-handler';
import { Blogs } from 'common/urls';

function Header(): ReactElement {
  let navigate = useNavigate();

  const { user, userData } = useContext(FirebaseContext);
  const [showSideMenu, setShowSideMenu] = useState(false);

  async function onSignOUt() {
    if (user === undefined || userData === undefined) return;
    await signOut(auth);
  }

  return (
    <>
      <Styled.Header>
        <Styled.HeaderViewport>
          <Link to="/">
            <Common.FlexRow width={300} alignItems="center" justifyContent="flex-start">
              <Common.SizedImage src={CommonImages.Logo} width={33} height={36} />
              <Common.SizedBoxW width={8} />
              <Typo.Yangjin fontSize={18} color={'#F25C54'} fontWeight={500}>
                노마드인서울
              </Typo.Yangjin>
            </Common.FlexRow>
          </Link>
          <Common.Span />

          <Common.NoOpacityButton
            onClick={() => {
              window.open(Blogs);
            }}
          >
            <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralBlack}>
              매거진
            </Typo.IBM_SemiBold>
          </Common.NoOpacityButton>

          <Common.SizedBoxW width={72} />

          <Link to="/events">
            <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralBlack}>
              이벤트
            </Typo.IBM_SemiBold>
          </Link>

          <Common.SizedBoxW width={72} />

          <Link to="/agits">
            <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralBlack}>
              아지트
            </Typo.IBM_SemiBold>
          </Link>

          <Common.Span />
          {user ? (
            <Common.FlexRow width={300} alignItems="center" justifyContent="flex-end">
              <Common.NoOpacityButton onClick={() => setShowSideMenu(true)}>
                <Common.FlexRow>
                  <Common.Circle width={30} height={30}>
                    <Common.SizedImage src={userData?.profile ?? CommonImages.ProfileDefault} width={30} height={30} objectFit="cover" />
                  </Common.Circle>

                  <Common.SizedBoxW width={8} />
                  <Common.SizedImage src={Icons.ExpandDownBlack} />
                </Common.FlexRow>
              </Common.NoOpacityButton>
            </Common.FlexRow>
          ) : (
            <Common.FlexRow width={300} alignItems="center" justifyContent="flex-end">
              <Link to="/signup">
                <Styled.SignUpButton>
                  <Typo.IBM_SemiBold color={'#F25C54'} fontSize={14}>
                    멤버 가입
                  </Typo.IBM_SemiBold>
                </Styled.SignUpButton>
              </Link>

              <Common.SizedBoxW width={8} />

              <Link to="/signin">
                <Styled.SignInButton>
                  <Typo.IBM_SemiBold color={Colors.neutralWhite} fontSize={14}>
                    로그인
                  </Typo.IBM_SemiBold>
                </Styled.SignInButton>
              </Link>
            </Common.FlexRow>
          )}

          {showSideMenu && (
            <OutsideClickHandler onOutsideClick={() => setShowSideMenu(false)}>
              <Styled.HeaderSideMenu>
                <Link to={`/namecard/${user?.uid}`}>
                  <Styled.HeaderSideMenuProfile>
                    <Common.Circle width={48} height={48}>
                      <Common.SizedImage src={userData?.profile ?? CommonImages.ProfileDefault} width={48} height={48} objectFit="cover" />
                    </Common.Circle>

                    <Common.SizedBoxW width={10} />

                    <Common.FlexColumn alignItems="flex-start" justifyContent="center">
                      <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralBlack}>
                        {userData?.username ?? '프로필을 설정해주세요.'}
                      </Typo.IBM_SemiBold>
                      <Typo.IBM_Regular fontSize={12} color={Colors.neutralBlack}>
                        {user?.email}
                      </Typo.IBM_Regular>
                    </Common.FlexColumn>
                  </Styled.HeaderSideMenuProfile>
                </Link>

                <Common.SizedBoxH height={24} />

                <Link to="/settings'">
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralBlack} textAlign="center">
                    설정
                  </Typo.IBM_SemiBold>
                </Link>

                <Common.SizedBoxH height={24} />

                <Common.NoOpacityButton
                  onClick={() => {
                    onSignOUt();
                    setShowSideMenu(false);
                  }}
                >
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralBlack} textAlign="center">
                    로그아웃
                  </Typo.IBM_SemiBold>
                </Common.NoOpacityButton>
              </Styled.HeaderSideMenu>
            </OutsideClickHandler>
          )}
        </Styled.HeaderViewport>
      </Styled.Header>
    </>
  );
}

export default Header;
