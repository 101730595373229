import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

export const FooterContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 100;

  padding: 8px 16px;
`;
