import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

import BackgroundImage from 'img/background/Background_0.png';

export const EmailVerifyContainer = styled.div`
  width: 100%;
  min-height: 764px;
  height: 100vh;

  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.63) 0%,
      rgba(255, 255, 255, 0.63) 32.81%,
      rgba(255, 255, 255, 0.56) 67.71%,
      rgba(255, 255, 255, 0.49) 100%
    ),
    url(${BackgroundImage});
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmailVerifySignOutButton = styled.button`
  border-radius: 90px;
  background: var(--S1, #ffe3e0);

  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;

  color: var(--P5, #f25c54);
  text-align: center;
  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const EmailVerifyResendButton = styled.button`
  padding: 12px 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 90px;
  background: var(--P5, #f25c54);

  color: var(--White, #fff);
  text-align: center;

  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  border: none;
`;
