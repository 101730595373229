import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { FirebaseContext } from 'context/firebaseContext';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from 'services/firebase';

import * as Styled from './signin.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { CommonImages, Icons, ProviderIcons } from 'common/images';
import { Colors } from 'common';
import { signWithGoogle, signin } from 'api/firebase-api';
import { needEmailVerification } from 'common/utils';
import validator from 'validator';

function SignIn(): ReactElement {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { user, userData } = useContext(FirebaseContext);

  const urlParams = new URLSearchParams(location.search);
  const redirect = urlParams.get('redirect');

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    if (needEmailVerification(user)) {
      navigate('/email-verify');
      return;
    }

    if (userData === undefined) return;

    if (!userData || !userData.membership) {
      navigate('/select-membership');
      return;
    }

    if (redirect) {
      navigate(`/${redirect}`);
      return;
    }

    navigate('/');
    return;
  }, [user, userData]);

  async function onSignIn() {
    const emailValue = (email ?? '').trim();
    const passwordValue = (password ?? '').trim();

    if (!emailValue) {
      alert('이메일을 입력해주세요.');
      return;
    }

    if (!passwordValue) {
      alert('비밀번호를 입력해주세요.');
      return;
    }

    if (!validator.isEmail(emailValue)) {
      alert('이메일 형식이 올바르지 않습니다.');
      return;
    }

    try {
      const user = await signin(emailValue, passwordValue);
    } catch (error: any) {
      if (error.code === 'auth/wrong-password') {
        alert('비밀번호가 틀렸습니다.');
      } else {
        alert(error);
      }
    } finally {
      setEmail('');
      setPassword('');
    }
  }

  return (
    <Styled.SignInContainer>
      <Styled.BackButton onClick={() => navigate(-1)}>
        <Common.SizedImage src={Icons.Back} width={20} height={21} />
      </Styled.BackButton>

      <Common.FlexRow alignItems="center" justifyContent="center">
        <Link to="/">
          <Common.SizedImage src={CommonImages.Logo} width={58.4} height={64} />
        </Link>
        <Common.SizedBoxW width={16} />
        <Typo.Yangjin fontSize={54} color="#F25C54">
          Welcome home
        </Typo.Yangjin>
      </Common.FlexRow>

      <Common.SizedBoxH height={80} />

      <Styled.SignInInput value={email} onChange={(e) => setEmail(e.target.value)} placeholder="이메일" type="email" />

      <Common.SizedBoxH height={12} />

      <Styled.SignInInput value={password} onChange={(e) => setPassword(e.target.value)} placeholder="비밀번호" type="password" />

      <Common.SizedBoxH height={16} />

      <Common.FlexRow width={300} alignItems="center">
        <Common.Span />

        <Link to="/send-reset-password">
          <Typo.IBM_SemiBold fontSize={12} color="#F8F8F8">
            이메일/비밀번호를 잊어버렸어요
          </Typo.IBM_SemiBold>
        </Link>
      </Common.FlexRow>

      <Common.SizedBoxH height={24} />

      <Styled.SignInConfirmButton onClick={onSignIn}>로그인</Styled.SignInConfirmButton>

      <Common.SizedBoxH height={24} />

      <Common.FlexRow width={320} alignItems="center" justifyContent="space-between">
        <Common.Dash width={123.5} height={0.4} color="#393939" />
        <Typo.IBM_Regular fontSize={14} color="#393939">
          또는
        </Typo.IBM_Regular>
        <Common.Dash width={123.5} height={0.4} color="#393939" />
      </Common.FlexRow>

      <Common.SizedBoxH height={24} />

      <Styled.SignInGoogleButton onClick={() => signWithGoogle()}>
        <Common.SizedImage src={ProviderIcons.Google} width={18} height={18} />
        <Common.SizedBoxW width={8} />
        <Typo.IBM_SemiBold fontSize={16} color="#393939">
          구글 계정으로 시작하기
        </Typo.IBM_SemiBold>
      </Styled.SignInGoogleButton>

      <Common.SizedBoxH height={36} />

      <Common.FlexRow>
        <Typo.IBM_Regular fontSize={14} color="#F25C54">
          노마드인서울이 처음이신가요?&nbsp;
        </Typo.IBM_Regular>
        <Link to="/signup">
          <Typo.IBM_SemiBold fontSize={14} color="#F25C54" textDecoration="underline">
            멤버 가입하기
          </Typo.IBM_SemiBold>
        </Link>
      </Common.FlexRow>
    </Styled.SignInContainer>
  );
}

export default SignIn;
