export const Blogs = 'https://blog.nomadinseoul.com';
export const Agits = 'https://beta.nomadinseoul.com/nomadagit';
export const Events = 'https://beta.nomadinseoul.com/nomadevents';
export const Challenges = 'https://beta.nomadinseoul.com/nomadchallenge';

export const DiscordRedirectUrl = new URL('/discord-account', window.location.origin).href;
export const DiscordOauth2Url = `https://discord.com/api/oauth2/authorize?client_id=1143495886681419847&redirect_uri=${encodeURIComponent(
  DiscordRedirectUrl,
)}&response_type=code&scope=guilds%20identify%20email%20guilds.join`;

export const DiscordInviteUrl = 'https://discord.gg/69PyEj78cb';

export const EventHostingUrl = 'https://docs.google.com/forms/d/1_CHYcyOSHc_WxpKX1bbaBAi-UFCFmM0vEd_MYgYR53w/edit';

export const MembershipBenefitsUrl = 'https://nomadinseoul.notion.site/5ad6712d89fc45959de43ec3d632392c?pvs=4';
export const PrivacyPolicyUrl = 'https://docs.google.com/document/d/1x-Hgq9CTVhA8CytS_DnifN8skGEYMy0sc9wf1GMCmmo/edit?usp=sharing';
export const TermsUrl =
  'https://docs.google.com/document/d/1aTMTJS-SOb0YeQplqRX6pch9bp04HzU6/edit?usp=sharing&ouid=114123602957167706323&rtpof=true&sd=true';
