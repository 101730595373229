import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

import BackgroundImage from 'img/background/Background_0.png';
import MembershipCardImage from 'img/membership/MembershipCard.png';
import NormalBackgroundImage from 'img/background/Background_1.png';
import PremiumBackgroundImage from 'img/background/Background_2.png';
import PremiumBenefitsImage from 'img/membership/PremiumMembershipBorder.png';

export const SelectMembershipContainer = styled.div`
  width: 100%;
  min-height: 764px;
  height: 100vh;

  background-image: linear-gradient(
      0deg,
      #fff 46.58%,
      rgba(255, 255, 255, 0.7) 69.79%,
      rgba(255, 255, 255, 0.7) 100%,
      #fff 100%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(${BackgroundImage});
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SelectMembershipCard = styled.button`
  width: 268px;
  height: 402px;

  background: url(${MembershipCardImage}), #f0eeec;
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 24px;
  box-shadow: 0px 15px 30px 0px rgba(90, 90, 90, 0.1);

  border: none;
  padding: 0;

  color: var(--Black, #000);
  text-align: center;
  font-family: IBMPlexSansKR;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const NormalMemberContainer = styled.div`
  width: 100%;
  min-height: 764px;
  height: 100vh;

  background-image: linear-gradient(
      0deg,
      #fff 46.58%,
      rgba(255, 255, 255, 0.7) 69.79%,
      rgba(255, 255, 255, 0.7) 100%,
      #fff 100%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(${NormalBackgroundImage});
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PremiumMemberContainer = styled.div`
  width: 100%;
  min-height: 764px;
  height: 100vh;

  background-image: linear-gradient(
      0deg,
      #fff 46.58%,
      rgba(255, 255, 255, 0.7) 69.79%,
      rgba(255, 255, 255, 0.7) 100%,
      #fff 100%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(${PremiumBackgroundImage});
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NormalMemberCard = styled.div`
  width: 288px;
  height: 413px;

  border-radius: 24px;
  background: linear-gradient(189deg, rgba(57, 57, 57, 0) 49.22%, rgba(57, 57, 57, 0.8) 90.31%), url(${NormalBackgroundImage});
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 24px;
  box-shadow: 0px 15px 30px 0px rgba(90, 90, 90, 0.1);
`;

export const NormalMemberCardContents = styled.div`
  width: 272.691px;
  height: 396.562px;
  border-radius: 24px;

  border: 1px solid var(--Frame, #fff);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  padding: 58px 0;
`;

export const PremiumMemberCard = styled.div`
  width: 288px;
  height: 413px;

  border-radius: 24px;
  background: linear-gradient(189deg, rgba(57, 57, 57, 0) 49.22%, rgba(57, 57, 57, 0.8) 90.31%), url(${NormalBackgroundImage});
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 24px;
  box-shadow: 0px 15px 30px 0px rgba(90, 90, 90, 0.1);
`;

export const PremiumMemberCardContents = styled.div`
  width: 272.691px;
  height: 396.562px;
  border-radius: 24px;

  border: 1px solid var(--Frame, #fff);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  padding: 58px 0;
`;

export const PremiumMembershipBenefits = styled.div`
  width: 328px;
  height: 268px;

  background: url(${PremiumBenefitsImage});
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
`;

export const PremiumMembershipBenefitsTitle = styled.div`
  width: 208px;
  height: 21px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 60.14px;
  top: -11px;
`;

export const SelectMemberPrevButton = styled.button`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;

  border-radius: 90px;
  background: var(--G1, #f8f8f8);

  color: var(--G3, #8e8e8e);
  text-align: center;
  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  border: none;

  height: 45px;
`;

export const SelectMemberNextButton = styled.button`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;

  border-radius: 90px;
  background: var(--P5, #f25c54);

  color: var(--White, #fff);
  text-align: center;
  font-family: IBM Plex Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  border: none;

  height: 45px;
`;

export const SelectMemberFinish = styled.div<{ membership?: MembershipType }>`
  width: 100%;
  min-height: 764px;
  height: 100vh;

  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.63) 0%,
      rgba(255, 255, 255, 0.63) 32.81%,
      rgba(255, 255, 255, 0.56) 67.71%,
      rgba(255, 255, 255, 0.49) 100%
    ),
    url(${(p) => (p.membership === 'premium' ? PremiumBackgroundImage : NormalBackgroundImage)});
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
