import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

import MainHeroMask from 'img/main/Hero_vector.svg';

export const MainContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  white-space: pre-line;
  padding: 72px 0 0 0;
`;

export const MainBackground = styled.div`
  width: 1190.039px;
  height: 621.833px;

  -webkit-mask-image: url(${MainHeroMask});
  mask-image: url(${MainHeroMask});

  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  position: relative;
  overflow: hidden;
`;

export const MainBackgroundImages = styled.div<{ count: number; index: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(p) => p.count * 1190.039}px;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;

  transform: translateX(${(p) => p.index * -1190.039}px);
`;

export const MainBackgroundImage = styled.img`
  object-fit: cover;

  width: 1190.039px;
  height: 100%;

  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;

  filter: grayscale(1);

  :hover {
    transform: scale(1.2);
    filter: grayscale(0);
  }
`;

export const MainBackgroundTexts = styled.div`
  padding: 96px 80px 96px 500px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 1;

  background: radial-gradient(
    49.47% 61.85% at 96.85% 89.63%,
    rgba(57, 57, 57, 0.9) 0%,
    rgba(57, 57, 57, 0.6) 62.2%,
    rgba(57, 57, 57, 0) 100%
  );

  pointer-events: none;
`;

export const MainPageButton = styled.button`
  width: 45px;
  height: 45px;

  border-radius: 90px;
  background: var(--g-1, #f8f8f8);

  box-shadow: 0px 8px 15px 0px rgba(90, 90, 90, 0.15);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;
  border: none;
`;

export const MainAskMembership = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(57, 57, 57, 0.2);
`;

export const MainAskMembershipWindow = styled.div`
  width: 301px;
  padding: 48px 36px 36px 36px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 24px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 12px 20px 0px rgba(90, 90, 90, 0.1);
  backdrop-filter: blur(10px);
`;

export const MainAskMembershipButton = styled.button`
  padding: 12px 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 90px;
  background: var(--P5, #f25c54);

  border: none;

  color: var(--White, #fff);
  text-align: center;

  font-family: IBMPlexSansKR;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
